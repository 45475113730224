import React from 'react'
import { ModalOverlay } from '../common/ModalOverlay/ModalOverlay'
import coinsImg from '../../assets/Coins.svg'
import largeCoinImg from '../../assets/coin-with-shadow.svg'
import { useDispatch, useSelector } from 'react-redux'
import rewardBg from '../../assets/rewardbg.png'
import {
  CoinsImage,
  CongratsButton,
  CongratsScreen
} from './DailyRewardsModal.styles'
import { hideReward } from '../../redux/communityReducers/CheckInReducer'

const styles = {}

const CONGRATS_MESSAGE_STYLE = {
  fontSize: 24,
  fontWeight: 'bold',
  textAlign: 'center'
}

export const RewardModal = () => {
  const dispatch = useDispatch()
  const { rewardAmount, isSpecialTaskReward } = useSelector(
    state => state.checkIn
  )

  return (
    <ModalOverlay>
      <CongratsScreen>
        <CoinsImage src={coinsImg} alt="" />
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
          <img src={largeCoinImg} alt="" />
          <div
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#FF5E6D',
              height: 30,
              marginTop: 10,
              marginLeft: 2
            }}
          >
            + {rewardAmount}
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div style={CONGRATS_MESSAGE_STYLE}>Congratulations!</div>
          <div
            style={{
              fontSize: 18,
              marginTop: 18,
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              maxWidth: '80vw'
            }}
          >
            You have earned{' '}
            <span style={{ fontWeight: 'bold' }}>+{rewardAmount} Dcoins</span>{' '}
            {isSpecialTaskReward
              ? 'for completed special tasks.'
              : 'from daily tasks.'}
          </div>
        </div>
        <CongratsButton onClick={() => dispatch(hideReward())}>
          OK
        </CongratsButton>
      </CongratsScreen>
    </ModalOverlay>
  )
}
