import dynamic from 'next/dynamic'
import React, { useMemo } from 'react'
import { createGlobalStyle } from 'styled-components'
import { PaymentProvider } from '../context/payment'

const Elements = dynamic(() =>
  import('@stripe/react-stripe-js').then(res => res.Elements, {
    ssr: false
  })
)
import { loadStripe } from '@stripe/stripe-js'

export const GlobalStyle = createGlobalStyle`
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 20px 14px;
    font-family: Helvetica Neue;
    font-size: 14px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    color: ${props => (props.scheme === 'dark' ? '#FFFFFF' : '#000000')};
    background: ${props => (props.scheme === 'dark' ? '#343434' : '#FFFFFF')}; 
    border: 1px solid ${props =>
      props.scheme === 'dark' ? '#4F4F4F' : '#E5E5E5'};  
  }
  
  .StripeElement--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  
  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  
  .StripeElementFull.PaymentRequestButton {
    height: 40px;
  }

  .StripeElementFull {
    display: block;
    margin: 10px 0 20px 0;
    padding-left: 10px;
    font-family: Helvetica Neue;
    font-size: 14px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    color: ${props => (props.scheme === 'dark' ? '#FFFFFF' : '#000000')};
    background: ${props => (props.scheme === 'dark' ? '#343434' : '#F5F5F5')}; 
  }
  
  .StripeElementFull--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  
  .StripeElementFull.IdealBankElement,
  .StripeElementFull.FpxBankElement,
  .StripeElementFull.PaymentRequestButton {
    padding: 0;
  }
  
  .StripeElementFull.PaymentRequestButton {
    height: 40px;
  }
`

const PurchaseProvider = ({
  scheme,
  appid,
  token,
  children,
  disableElements
}) => {
  const provider = (
    <PaymentProvider token={token} appid={appid}>
      <GlobalStyle scheme={scheme} />
      {children}
    </PaymentProvider>
  )

  const stripeProvider = useMemo(() => {
    return appid == 3
      ? loadStripe(
          'pk_live_51HDYfWDVqvipRL9oBUKMdRIktXYteF9zrYORNU7B4YuooNDU4ffu8sCQ9r7tFqcs8ifPeXFR8tdUwt1UToCW63LS00eb2mxZds'
        )
      : loadStripe(
          'pk_test_51HDYfWDVqvipRL9o76mRptaX7WTDvUmZ01NTdjxvH8HNQjDIMKFn0CAJHhyxkbwQ16ZQJ1cFKhxBC0v50k5925a800ht9WgUZa'
        )
  }, [appid])

  if (disableElements) return provider

  return <Elements stripe={stripeProvider}>{provider}</Elements>
}

export default PurchaseProvider
