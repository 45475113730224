import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import closeImg from '../../assets/exit.svg'
import {
  FollowAction,
  unFollowAction
} from '../../redux/actions/FollowingActions'
import {
  closeFollowModalAndUpdate,
  getRecommendedUsers
} from '../../redux/communityReducers/CheckInReducer'
import ProfileImage from '../../routes/Profile/components/profile/ProfileImage'
import ModalOverlay from '../common/ModalOverlay'
import * as styles from './fd.module.css'
import Link from 'next/link'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useIsTablet } from '../../hooks/useIsTablet'

export const DesignerCard = ({
  user,
  id,
  numFollowers,
  inspirations,
  followed
}) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const dispatch = useDispatch()

  const handleFollow = () => {
    dispatch(FollowAction(id))
  }

  const handleUnfollow = () => {
    dispatch(unFollowAction(id))
  }

  let inspirationImages = inspirations
    .slice(0, 4)
    .map(inspiration => (
      <div
        key={inspiration.objectId}
        className={styles.inspirationImage}
        style={{ backgroundImage: `url(${inspiration.idea.cfThumbImageUrl})` }}
      />
    ))

  return (
    <div className={styles.designerCard} key={id}>
      <Link href={`/dm/${id}`}>
        <ProfileImage
          size={isMobile || isTablet ? 66 : 90}
          user={user}
          onClick={() => {
            dispatch(closeFollowModalAndUpdate())
          }}
        />
      </Link>
      <div className={styles.designerDetails}>
        <div>
          <div className={styles.designerName}>
            {user.uniqueDisplayName || user.username}
          </div>
          <div className={styles.designerFollowerCount}>
            {numFollowers || 0} {numFollowers === 1 ? 'follower' : 'followers'}{' '}
          </div>
        </div>
        <FollowButton
          following={followed}
          handleFollow={handleFollow}
          handleUnfollow={handleUnfollow}
        />
      </div>
      <div className={styles.inspirations}>{inspirationImages}</div>
    </div>
  )
}

const FollowDesigners = () => {
  const dispatch = useDispatch()
  const { recommendedDesigners, recommendedLoading, recommendedLoadMore } =
    useSelector(state => state.checkIn)
  const { following } = useSelector(state => state.follows)
  const scrollListRef = useRef(null)

  let designerElements = []
  if (recommendedDesigners && recommendedDesigners.length) {
    designerElements = recommendedDesigners.map(
      ({ user, userInspirations }) => {
        return (
          <DesignerCard
            key={user.objectId}
            id={user.objectId}
            user={user}
            numFollowers={user.numFollowers}
            inspirations={userInspirations}
            followed={following.includes(user.objectId)}
          />
        )
      }
    )
  }

  useEffect(() => {
    const ref = scrollListRef.current

    const listener = event => {
      const el = event.target

      if (
        el.scrollTop !== 0 &&
        el.scrollHeight - el.scrollTop - el.clientHeight < 100 &&
        !recommendedLoading &&
        recommendedLoadMore
      ) {
        dispatch(getRecommendedUsers(recommendedDesigners.length))
      } else if (recommendedLoading) {
      }
    }
    if (ref) ref.addEventListener('scroll', listener, false)

    return () => {
      if (ref) ref.removeEventListener('scroll', listener)
    }
  }, [recommendedLoading, scrollListRef, dispatch])

  return (
    <ModalOverlay>
      <div className={styles.followModal}>
        <img
          src={closeImg}
          alt=""
          className={styles.closeButton}
          onClick={() => dispatch(closeFollowModalAndUpdate())}
        />
        <div className={styles.followHeader}>More people you may like</div>
        <div className={styles.scrollWrapper} ref={scrollListRef}>
          <div className={styles.designerList}>{designerElements}</div>
        </div>
      </div>
    </ModalOverlay>
  )
}

const FollowButton = ({ following, handleFollow, handleUnfollow }) => {
  if (following) {
    return (
      <button onClick={handleUnfollow} className={styles.followButtonFollowing}>
        {' '}
        Following{' '}
      </button>
    )
  } else {
    return (
      <button onClick={handleFollow} className={styles.followButton}>
        {' '}
        Follow{' '}
      </button>
    )
  }
}

export default FollowDesigners
