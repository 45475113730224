import styled from 'styled-components'
import dailyRewardsBubbleBg from '../../assets/dailyRewardsBubbleBg.png'
import dailyRewardsBubbleBgMobile from '../../assets/dailyRewardsBubbleBgMobile.png'
import rewardBg from '../../assets/rewardbg.png'
import membershipAdBg from '../../assets/dailyRewards/membershipAdBg.png'

export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 1000;

  @media (max-width: 1023px) {
    margin-top: 60px;
    z-index: 899;
    height: calc(100vh - 60px);
  }
`

export const Dimmer = styled(Container)`
  background: rgba(0, 0, 0, 0.6);

  @media (max-width: 1023px) {
    display: none;
  }
`

export const ModalContainer = styled.div`
  position: relative;
  width: 992px;
  height: 632px;
  background: #fff1f2;
  border-radius: 16px;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
`

export const UserInfoPanel = styled.div`
  background: url(${dailyRewardsBubbleBg}) #fffdfd;
  width: 417px;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1023px) {
    background: url(${dailyRewardsBubbleBgMobile}) #fffdfd;
    background-size: cover;
    flex-direction: row-reverse;
    gap: 21px;

    width: 100%;
    height: 155px;

    & h2 {
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 14px;
      margin-top: 0;
    }
  }
`

export const MobileWelcomeAndCountdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const AvatarAndName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TasksContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  overflow: visible;
  height: 100%;

  @media (max-width: 800px) {
    height: unset;
    overflow: scroll;
  }
`

export const Username = styled.strong`
  margin-top: 24px;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.01em;
  cursor: pointer;

  @media (max-width: 1023px) {
    margin-top: 12px;
    font-size: 10px;
    letter-spacing: 0.1px;
  }
`

export const MyBalanceLabel = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: #000000;
  margin-top: 68px;

  @media (max-width: 1023px) {
    margin-top: 0;
    font-size: 14px;
  }
`

export const BalancePlank = styled.div`
  background: #ff5e6d;
  border-radius: 100px;
  padding: 7px 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
  margin-top: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  & span {
    font-weight: 700;
    margin-right: 3px;
  }

  @media (max-width: 1023px) {
    margin-top: 0;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    padding: 6px 7px;

    & img {
      width: 18px;
      height: 18px;
    }
  }
`

export const CountdownLabel = styled.div`
  display: flex;
  position: absolute;
  bottom: ${props => (props.isMember ? '31px' : '76px')};
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    bottom: 17px;
    font-size: 14px;
    position: initial;
    left: 0;
    justify-content: flex-start;
  }
`

export const TasksTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;

  & span {
    color: #ff5e6d;
  }

  @media (max-width: 1023px) {
    margin-bottom: 0;
    font-size: 16px;
  }
`

export const TasksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  padding: 0 24px 17px 24px;

  @media (max-width: 1023px) {
    padding: 14px;
    padding-bottom: 200px;
  }
`

export const TaskCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 20px 15px 18px;
  background: white;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1)); */
  border: none;
  border-radius: 11px;
  cursor: pointer;

  @media (max-width: 1023px) {
    padding: 15px 10px 15px 16px;
  }
`

export const TaskIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${props => (props.inverted ? '#FF5E6D' : '#fff1f2')};
  content: '';
  box-sizing: border-box;
  margin-right: 17px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & img {
    max-width: 80%;
    max-height: 80%;
  }

  @media (max-width: 1023px) {
    flex-shrink: 0;
    margin-right: 12px;

    & img {
      max-width: 50%;
      max-height: 50%;
    }
  }
`

export const TaskMetadata = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TaskTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: block;

  @media (max-width: 1023px) {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
  }
`

export const TaskButton = styled.button`
  background: transparent;
  border: 2px solid #ff5e6d;
  border-radius: 32px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5e6d;
  padding: 6px 9px;
  cursor: pointer;
  height: 36px;
  width: 113px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.inverted && `background: white;`}

  & img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }

  ${({ completed }) =>
    completed &&
    `
    background: #ff5e6d;
    color: white;
    `}

  &:hover {
    background: #ff5e6d;
    color: white;
  }

  @media (max-width: 1023px) {
    height: 36px;
    width: 103px;
    border-radius: 32px;
    font-size: 13px;
    justify-content: center;

    & img {
      width: 20px;
      margin-right: 5px;
    }
  }

  ${props =>
    props.claimed &&
    `
    border: 2px solid #F5F5F5;
    color: #979797;
    background: #F5F5F5;
    opacity: 1;
    padding: 6px 16px;

    & img {
      width: 14px;
      height: auto;
      margin-right: 8px;
    }

    &:hover {
      background: #F5F5F5;
      color: #9B9B9B;
    }
  `}
`

export const ProgressBar = styled.div`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 8px;
  background: ${props => (props.inverted ? 'white' : '#e5e5e5')};
  border-radius: 5px;
  overflow: hidden;
  margin-right: 6px;
  ${props => props.inverted && 'border: 1px solid #FF5E6D;'}

  @media (max-width: 1023px) {
    width: 80px;
  }
`

export const ProgressInnerBar = styled.div`
  content: '';
  width: ${props => props.progress}%;
  height: 8px;
  position: absolute;
  left: 0;
  top: 0;
  background: #ff5e6d;
  border-radius: 3.5px;
`

export const ProgressLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #8b8b8b;
`

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 24px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  z-index: 100;

  @media (max-width: 1023px) {
    top: 15px;
    right: 10px;
  }
`

export const ModalTitle = styled.div`
  position: absolute;
  top: 19px;
  left: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.02em;
  color: #000000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  & img {
    margin-right: 8px;
  }

  @media (max-width: 1023px) {
    top: 16px;
    left: 16px;
    display: none;
  }
`

export const TaskListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 24px;

  @media (max-width: 1023px) {
    padding: 17px 14px 5px;
  }
`

export const MobileBalancePlank = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const AvatarWrapper = styled.div`
  width: 172px;
  height: 172px;
  cursor: pointer;
  position: relative;

  @media (max-width: 1023px) {
    width: 85px;
    height: 85px;

    & > * {
      transform: scale(0.5);
      transform-origin: 0 0;
    }
  }
`

export const CongratsScreen = styled.div`
  height: 632px;
  width: 993px;
  border-radius: 16px;
  background-size: 100%;
  background-image: url(${rewardBg}),
    linear-gradient(90.27deg, #fff1f2 0.24%, #ffffff 100.68%);
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CoinsImage = styled.img`
  margin-top: 150px;
  flex: none;
`

export const CongratsButton = styled.button`
  margin-top: 20px;
  height: 42px;
  width: 166px;
  flex: none;
  border-radius: 72px;
  background: #ff5e6d;
  font: 16px Helvetica Neue;
  color: #ffffff;
  border: none;
  position: relative;
  font-weight: bold;
`

export const MembershipAds = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url(${membershipAdBg});
  background-size: cover;
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding-left: 18px;
  padding-right: 12px;
  box-sizing: border-box;

  & > div {
    display: flex;
    align-items: center;

    & > span {
      margin-left: 8px;
    }
  }

  @media (max-width: 800px) {
    position: fixed;
    z-index: 999;
  }
`

export const SubmitButton = styled.button`
  cursor: pointer;
  background: #ff5e6d;
  border: 2px solid #ff5e6d;
  border-radius: 37px;
  width: 152px;
  height: 34px;
  color: #ffffff;
  font-weight: 700;
  font-size: 13px;

  cursor: ${props => (props.disabled ? 'default' : 'cursor')};
  background: ${props => props.disabled && `#e5e5e5`};
  border: ${props => props.disabled && `2px solid #e5e5e5`};
  color: ${props => props.disabled && `#b5b5b5`};

  &:hover {
    opacity: ${props => (props.disabled ? 1 : 0.9)};
  }
`

export const MembershipPlank = styled.div`
  position: absolute;
  top: 12px;
  right: 13px;
  cursor: pointer;
`

export const MobileWelcomeLine = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & h2 {
      margin-bottom: 0;
    }
  }
`

export const AvatarAccessory = styled.img.attrs(props => ({
  alt: props.alt || ''
}))`
  width: 190px;
  height: 190px;
  position: absolute;
  left: -8px;
  top: -10px;
  z-index: 99;
`

export const SpecialTaskCard = styled(TaskCard)`
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  background: url(${props => props.backdrop}), #fdf4fa;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const BeforeText = styled.span`
  color: #979797;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  display: block;
  margin-top: 6px;
`
