import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import ModalOverlay from '../../common/ModalOverlay'

import coinIcon from '../../../assets/coin.svg'
import membershipImage from '../../../assets/membership/success-membership.png'
import coinsImage from '../../../assets/membership/coin5.svg'
import { loadMe } from '../../../routes/Profile/store/profileReducer'

const Root = styled.div`
  background-color: #fff;
  width: min(90vw, 720px);
  height: min(770px, 90vh);
  border-radius: 10px;
  overflow-y: auto;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding: 15px 30px 30px;
    box-sizing: border-box;
    margin: auto;

    max-height: 542px;
    max-width: 342px;
    border-radius: 10px;
    justify-content: center;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  font-family: Helvetica Neue;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.01em;

  & img {
    width: 20px;
    height: 20px;
    margin: 0 5px 5px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    padding: 0;
    margin-bottom: auto;
    & img {
      width: 18px;
      height: 18px;
    }
  }
`

const Title = styled.div`
  max-width: 500px;
  margin: 70px auto 0;
  font-family: Helvetica Neue;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 30px;
    margin: 50px auto 0;
  }
`

const Description = styled.div`
  max-width: 550px;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const Button = styled.button`
  height: 50px;
  width: 250px;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  border-radius: 24px;
  border: 0;
  background: #ff5e6d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 30px;

  @media only screen and (max-width: 600px) {
    margin-bottom: auto;
  }
`

const Image = styled.img`
  height: min(400px, 50vh);
  width: 100%;
  object-fit: contain;

  @media only screen and (max-width: 600px) {
    max-height: 150px;
    margin: 25 px 0;
  }
`

const SuccessModal = ({ data, onClose }) => {
  const dispatch = useDispatch()
  const me = useSelector(state => state.profile.me)

  const contentMap = {
    membership: {
      title: 'Congratulations! Your earned the membership badge.',
      description:
        'Thank you for your payment. We received your payment and your membership immediate effects.',
      image: membershipImage
    },
    coins: {
      title: `Congratulations! Your earned ${data.name}.`,
      description:
        'Thank you for your payment. We received your payment and your DCoins are already credited.',
      image: coinsImage
    },
    ItemBundle: {
      title: 'Congratulations! Your earned the bundle.',
      description:
        'Thank you for your payment. We received your payment and your bundle is already given.',
      image: data.imageUrl
    }
  }

  const { title, description, image } =
    contentMap[data.className || data.nickname]

  useEffect(() => {
    setTimeout(() => {
      dispatch(loadMe())
    }, 2000)
  }, [])

  return (
    <ModalOverlay fullscreen onClose={onClose}>
      <Root>
        <Header>
          DCoin Balance: <img src={coinIcon} alt="dcoin" /> {me?.numCoins}
        </Header>
        <Title>{title}</Title>
        <Image src={image} />
        <Description>{description}</Description>
        <Button onClick={onClose}>Done</Button>
      </Root>
    </ModalOverlay>
  )
}

export default SuccessModal
