import Link from 'next/link'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import closeImg from '../../assets/exit.svg'
import {
  FollowAction,
  unFollowAction
} from '../../redux/actions/FollowingActions'
import {
  closeAllGiftModal,
  getUserInsGifts
} from '../../redux/communityReducers/GiftReducer'
import formatDate from '../../utils/formatDate'
import ModalOverlay from '../common/ModalOverlay'
import * as styles from './vg.module.css'

const CardItem = ({
  data,
  userPhoto,
  giftImg,
  userName,
  objectId,
  followingDate,
  count,
  followed
}) => {
  const myId = useSelector(state => state.profile?.me?.objectId)
  const dispatch = useDispatch()

  const handleFollow = () => {
    dispatch(FollowAction(data.objectId))
  }

  const handleUnfollow = () => {
    dispatch(unFollowAction(data.objectId))
  }

  const directProfile = () => {
    dispatch(closeAllGiftModal())
  }

  let dateFollow = formatDate(followingDate)
  let countDiv =
    count === 1 ? null : (
      <div className={styles.giftNum}>
        {' '}
        <img loading="lazy" src={giftImg} alt="Gift" /> x{count}
      </div>
    )
  let username =
    userName === undefined ? `DM_User_${data.objectId.slice(-2)}` : userName
  return (
    <div className={styles.giftAllItemsBody}>
      <div className={styles.giftAllItemsInfo}>
        <div className={styles.giftAllItemsPhoto}>
          <Link href={'/dm/' + objectId}>
            <img src={userPhoto} onClick={directProfile} alt="Gift" />
          </Link>
          <img
            loading="lazy"
            src={giftImg}
            className={styles.giftImage}
            alt="Gift"
          />
        </div>
        <div className={styles.userInfo}>
          <Link href={'/dm/' + objectId}>
            <p className={styles.userName}>{username}</p>
          </Link>
          <p className={styles.followingDate}>{dateFollow}</p>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {countDiv}
        {myId !== data.objectId && (
          <FollowButton
            following={followed}
            handleFollow={handleFollow}
            handleUnfollow={handleUnfollow}
          />
        )}
      </div>
    </div>
  )
}

const FollowButton = ({ following, handleFollow, handleUnfollow }) => {
  if (following) {
    return (
      <button onClick={handleUnfollow} className={styles.followButtonFollowing}>
        {' '}
        Following{' '}
      </button>
    )
  } else {
    return (
      <button onClick={handleFollow} className={styles.followButton}>
        {' '}
        Follow{' '}
      </button>
    )
  }
}

const AllGiftModals = ({ url }) => {
  const dispatch = useDispatch()
  const { allGiftModal, numGift, userInsIds, userInsGiftList } = useSelector(
    state => state.gift
  )
  const { following } = useSelector(state => state.follows)

  const close = () => {
    dispatch(closeAllGiftModal())
  }
  useEffect(() => {
    dispatch(getUserInsGifts(userInsIds))
  }, [userInsIds, dispatch])
  const useOutsideAlerter = (ref, closeMenu, active) => {
    const dispatch = useDispatch()

    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(closeMenu())
      }
    }

    useEffect(() => {
      if (active) document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    })
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, closeAllGiftModal, allGiftModal)

  if (!allGiftModal) {
    return null
  }
  let userlist = {}
  let usersins
  if (userInsGiftList.length > 0) {
    usersins = [...userInsGiftList]
    usersins.sort(function (a, b) {
      return new Date(a.updatedAt) - new Date(b.updatedAt)
    })

    usersins.forEach((user, index) => {
      console.log(user.sendingUser)
      if (userlist[user.sendingUser.username]) {
        userlist[user.sendingUser.username].count += user.numDups
          ? user.numDups + 1
          : 1
        userlist[user.sendingUser.username].followingDate = user.updatedAt
        userlist[user.sendingUser.username].giftImg = user.virtualGift.imageUrl
      } else {
        userlist[user.sendingUser.username] = {
          count: user.numDups ? user.numDups + 1 : 1,
          objectId: user.sendingUser.objectId,
          userPhoto:
            user.sendingUser?.funAvatarImageUrl ||
            user.sendingUser?.thumbProfileImageFile?.url ||
            user.sendingUser.cfTbImageUrl ||
            'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png',
          giftImg: user.virtualGift.imageUrl,
          followingDate: user.updatedAt,
          displayName: user.sendingUser.uniqueDisplayName
        }
      }
    })
  }
  let users = []
  let arr = Object.keys(userlist).map(k => userlist[k])
  arr.sort(function (a, b) {
    return new Date(b.followingDate) - new Date(a.followingDate)
  })
  if (userlist) {
    users = arr.map((user, index) => (
      <CardItem
        data={user}
        key={index}
        userPhoto={user.userPhoto}
        giftImg={user.giftImg}
        userName={user.displayName}
        followingDate={user.followingDate}
        count={user.count}
        objectId={user.objectId}
        followed={following.includes(user.objectId)}
      />
    ))
  }

  return (
    <ModalOverlay>
      <div className={styles.giftAllItems} ref={wrapperRef}>
        <div>
          <img
            src={closeImg}
            alt=""
            className={styles.closeButton}
            onClick={close}
          />
          <span className={styles.giftAllItemsTitle}>All Gifts {numGift}</span>
        </div>
        <div className={styles.giftAllItemsContent}>{users}</div>
        <button className={styles.sendButtonGift} onClick={close}>
          {' '}
          Close{' '}
        </button>
      </div>
    </ModalOverlay>
  )
}
export default AllGiftModals
