import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import floatingCheckinImg from '../../assets/FloatingCheckin.svg'
import floatinCheckinFocusedImg from '../../assets/FloatingCheckinFocus.svg'
import { gotoLogIn } from '../../redux/actions/AuthActions'
import { claimDailyTaskReward, claimWelcomeGiftReward, resetAndOpen } from '../../redux/communityReducers/CheckInReducer'
import determineProgressForTasks from '../../utils/determineProgressForTasks'
import { useDailyRewardsInfo } from '../../hooks/useDailyRewardsInfo'
import useDailyTasks from '../../hooks/useDailyTasks'

export const DailyRewardsChecker = () => {
  const { openDailyTask } = useDailyTasks()
  const dispatch = useDispatch()
  const authenticated = useSelector(state => state.auth.isAuthenticated)
  const { taskRecord, dailyTasks, welcomeRecord, welcomeTasks, loadingStatus } = useSelector(state => state.checkIn)
  const me = useSelector(state => state.profile.me)
  const { specialTaskList } = useDailyRewardsInfo()

  const taskClaimable = useMemo(() => {
    if (loadingStatus === 'loaded' && authenticated) {
      return determineProgressForTasks(taskRecord, welcomeRecord, dailyTasks, welcomeTasks, me).some(progress => progress.status === 'claimable')
    }

    return false
  }, [me, loadingStatus, welcomeTasks, dailyTasks, taskRecord, welcomeRecord])

  //THIS gets activated only when daily task modal opens and starts timer
  //OLD 2024
  /*
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("REFRESH TASK DATA")
      dispatch(attemptDailyTasksReset())

      determineProgressForTasks(
        taskRecord,
        welcomeRecord,
        dailyTasks,
        welcomeTasks,
        me
      )
        .filter(progress => !progress.claimed && progress.completed)
        .forEach(item => {
          if (item.className === 'WelcomeGift') {
            dispatch(claimWelcomeGiftReward(item.objectId))
          } else {
            const isSpecialTask = specialTaskList.some(
              task => item.objectId === task.objectId
            )

            dispatch(claimDailyTaskReward(item.objectId, isSpecialTask))
          }

          dispatch(resetAndOpen())
        })
    }, 30000) // EVERY SECOND WHY!! 1000

    return () => {
      clearInterval(interval)
    }
  }, [
    me,
    taskClaimable,
    taskRecord,
    welcomeRecord,
    me,
    welcomeTasks,
    dispatch,
    specialTaskList
  ])
  */

  //2024
  useEffect(() => {
    const refresh = async () => {
      determineProgressForTasks(taskRecord, welcomeRecord, dailyTasks, welcomeTasks, me)
        .filter(progress => !progress.claimed && progress.completed)
        .forEach(item => {
          if (item.className === 'WelcomeGift') {
            dispatch(claimWelcomeGiftReward(item.objectId))
          } else {
            const isSpecialTask = specialTaskList.some(task => item.objectId === task.objectId)

            dispatch(claimDailyTaskReward(item.objectId, isSpecialTask))
          }

          //dispatch(resetAndOpen())
        })

      //await openDailyTask()
    }

    refresh()
  }, [me, taskClaimable, taskRecord, welcomeRecord, me, welcomeTasks, dispatch, specialTaskList])

  return null
}
