import Link from 'next/link'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import deleteIcon from '../../../assets/modal/delete.svg'
import moreIcon from '../../../assets/modal/more.svg'
import warningIcon from '../../../assets/modal/warning.svg'
import commentImg from '../../../assets/social/comment.svg'
import repliesHiddenImg from '../../../assets/social/repliesHidden.svg'
import repliesVisibleImg from '../../../assets/social/repliesVisible.svg'
import {
  appendCommentLikes,
  closeCommentModal,
  deleteComment,
  getUserInsCommentReplies,
  openReportModal
} from '../../../redux/communityReducers/PostActionsReducer'
import ProfileImage from '../../../routes/Profile/components/profile/ProfileImage'
import formatDate from '../../../utils/formatDate'
import Dropdown from '../../common/DropdownMenu'
import LikeBtn from '../../social/LikeBtn'
import {
  CommentContentText,
  CommentExpandHideButton,
  CommentItemActions,
  CommentItemContent,
  CommentItemContentDate,
  CommentItemContentHeader,
  CommentItemContentUsername,
  CommentItemWrapper,
  CommentLikeReplyActions,
  CommentReplyButton,
  MoreButtonWrapper,
  MoreIcon
} from './Comments.styles'

export const CommentItem = ({
  id,
  inspirationId,
  user,
  date,
  username,
  text,
  likes,
  replies = '',
  reply = false,
  onReply = () => {},
  onDelete = () => {}
}) => {
  const dispatch = useDispatch()
  const currentUserId = useSelector(state => state.profile.me.objectId)
  const [repliesVisible, setRepliesVisible] = useState(false)
  const [repliesList, setRepliesList] = useState(null)
  let userName =
    username === undefined ? `DM_User_${user.objectId.slice(-2)}` : username

  useEffect(() => {
    if (repliesVisible && !repliesList) {
      getUserInsCommentReplies(id).then(result => {
        setRepliesList(result.userInspirationComments)
        dispatch(appendCommentLikes(result.currentUserLikes))
      })
    }
  }, [id, repliesVisible, repliesList, dispatch])

  const handleDeleteComment = useCallback(() => {
    dispatch(
      deleteComment({
        userInspirationCommentId: id,
        userInspirationId: inspirationId,
        completionCallback: onDelete
      })
    )
  }, [dispatch, id, inspirationId, onDelete])

  const handleReportComment = useCallback(() => {
    dispatch(openReportModal({ objectId: id, reportEntity: 'comment' }))
  }, [dispatch, id])

  const commentActions = useMemo(() => {
    const actions = []

    if (currentUserId === user.objectId) {
      actions.push({
        icon: deleteIcon,
        label: 'Delete',
        action: handleDeleteComment
      })
    } else {
      actions.push({
        icon: warningIcon,
        label: 'Report',
        action: handleReportComment
      })
    }

    return actions
  }, [handleDeleteComment, handleReportComment, currentUserId, user])

  const commentText = useMemo(() => {
    return text.replace(/@([a-z\d_]+)/gi, '<a href="/@$1">@$1</a>')
  }, [text])

  return (
    <>
      <CommentItemWrapper reply={reply}>
        <Link
          onClick={() => dispatch(closeCommentModal())}
          href={`/dm/${user.objectId}`}
        >
          <ProfileImage user={user} size={50} showLevel={false} />
        </Link>
        <CommentItemContent>
          <CommentItemContentHeader>
            <CommentItemContentUsername>{userName}</CommentItemContentUsername>
            <CommentItemContentDate>{formatDate(date)}</CommentItemContentDate>
          </CommentItemContentHeader>
          <CommentContentText
            dangerouslySetInnerHTML={{ __html: commentText }}
          />
        </CommentItemContent>
      </CommentItemWrapper>
      <CommentItemActions>
        {replies ? (
          <CommentExpandHideButton
            indent={reply}
            onClick={() => setRepliesVisible(!repliesVisible)}
          >
            <img
              src={repliesVisible ? repliesVisibleImg : repliesHiddenImg}
              alt=""
            />
            {repliesVisible ? 'Hide Replies' : 'View Replies'}
          </CommentExpandHideButton>
        ) : (
          <div />
        )}
        <CommentLikeReplyActions>
          <LikeBtn dimmed commentId={id} numLikes={likes} loc="comment" />
          <CommentReplyButton
            onClick={() => {
              onReply(userName, id, () => {
                getUserInsCommentReplies(id).then(result => {
                  setRepliesList(result.userInspirationComments)
                })
              })
            }}
          >
            <img src={commentImg} alt="" />
            <span>{replies}</span>
          </CommentReplyButton>
          <Dropdown
            label={
              <MoreButtonWrapper>
                <MoreIcon src={moreIcon} alt="" />
              </MoreButtonWrapper>
            }
            actions={commentActions}
          />
        </CommentLikeReplyActions>
      </CommentItemActions>
      {repliesList &&
        repliesVisible &&
        repliesList.map(data => (
          <CommentItem
            id={data.objectId}
            user={data.user}
            key={data.objectId}
            userImg={data.user.cfTbImageUrl}
            date={data.updatedAt}
            username={data.user.uniqueDisplayName}
            text={data.text}
            giftImg={data.vgImageUrl}
            likes={data.numLikes || 0}
            replies={data.numReplies || ''}
            onDelete={() => {
              getUserInsCommentReplies(id).then(result => {
                setRepliesList(result.userInspirationComments)
                dispatch(appendCommentLikes(result.currentUserLikes))
              })
            }}
            onReply={userName => {
              onReply(userName, id, () => {
                getUserInsCommentReplies(id).then(result => {
                  setRepliesList(result.userInspirationComments)
                })
              })
            }}
            reply
            inspirationId={inspirationId}
          />
        ))}
    </>
  )
}
