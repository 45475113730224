import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import coin2 from '../../../assets/membership/coin2.svg'
import coin4 from '../../../assets/membership/coin4.svg'
import coin5 from '../../../assets/membership/coin5.svg'

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 25px;

  @media only screen and (max-width: 600px) {
    margin: 15px 0 15px;
  }
`

const Package = styled.button`
  width: 170px;
  height: 270px;
  max-width: 25%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px #ff5e6d4d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 35px 0 25px;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  color: #0c0750;
  position: relative;

  & > * {
    flex-shrink: 0;
  }

  @media only screen and (max-width: 600px) {
    width: 100px;
    height: 150px;
    min-width: 25%;

    & img {
      width: 65%;
    }
  }
`

const Amount = styled.span`
  font-family: Helvetica Neue;
  font-size: 20px;
  font-weight: 700;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`

const Name = styled.span`
  margin: 5px auto 0;
  font-weight: 400;
  display: block;
`

const MainPackage = styled(Package)`
  width: 235px;
  height: 320px;
  max-width: 40%;
  background-image: linear-gradient(
    270deg,
    #ff9d74 0%,
    #ff5e6d 37.4%,
    #ff4f7e 74.69%,
    #ff6d90 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  @media only screen and (max-width: 600px) {
    width: 130px;
    height: 190px;
    min-width: 30%;
  }
`

const MainPackageContainer = styled.div`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 20px;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    width: calc(100% - 6px);
    height: calc(100% - 4px);
  }
`

const Price = styled.span`
  font-family: Helvetica Neue;
  font-size: 24px;
  font-weight: 700;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`

const Header = styled.span`
  padding-top: 1px;
  box-sizing: border-box;
  height: 35px;
  width: 80%;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 40px 40px;

  background: linear-gradient(
    270deg,
    #ff9d74 0%,
    #ff5e6d 37.4%,
    #ff4f7e 74.69%,
    #ff6d90 100%
  );

  @media only screen and (max-width: 600px) {
    height: ${props => (props.isSmall ? '22px' : '24px')};
    font-size: ${props => (props.isSmall ? '10px' : '12px')};
  }
`

const formatPrice = raw => {
  return (raw * 0.01).toFixed(2)
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const coinMap = [coin2, coin5, coin4]

const Packages = ({ packages, onSelect }) => {
  if (!packages?.length) return null

  console.log('packages: ', packages)

  const sorted = [
    {
      ...packages[0],
      override: {
        extra: 0,
        amount: 2000
      }
    },
    {
      ...packages[2],
      override: {
        extra: '10,000',
        amount: '50,000'
      }
    },
    {
      ...packages[1],
      override: {
        extra: '1,000',
        amount: '10,000'
      }
    }
  ]

  return (
    <>
      <Root>
        {sorted.map((item, idx) => {
          const {
            id,
            name,
            price,
            metadata,
            override: { extra, amount }
          } = item
          const formattedPrice = formatPrice(price.unit_amount)

          return idx !== 1 ? (
            <Package
              key={id}
              onClick={() => {
                onSelect(item)
              }}
            >
              {extra ? <Header isSmall>Extra {extra}</Header> : null}
              <Amount>
                {amount}
                <Name>Dcoins</Name>
              </Amount>
              <Image
                layout="fixed"
                width="57px"
                height="45px"
                src={coinMap[idx]}
                alt="coins"
              />
              <Price>${formattedPrice}</Price>
            </Package>
          ) : (
            <MainPackage
              onClick={() => {
                onSelect(item)
              }}
            >
              <MainPackageContainer>
                {extra ? <Header>Extra {extra}</Header> : null}
                <Amount>
                  {amount}
                  <Name>Dcoins</Name>
                </Amount>
                <Image
                  layout="fixed"
                  width="80px"
                  height="63px"
                  src={coinMap[idx]}
                  alt="coins"
                />
                <Price>${formattedPrice}</Price>
              </MainPackageContainer>
            </MainPackage>
          )
        })}
      </Root>
    </>
  )
}

export default Packages
