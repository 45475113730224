const determineProgressForTasks = (
  taskRecord,
  welcomeRecord,
  dailyTasks,
  welcomeTasks,
  me
) => {
  const {
    rewardedDtIds,
    numLikes: numberLikes,
    numFollows: numberFollows,
    numGifts: numberGifts,
    designTemplate,
    joinEvent,
    challenge,
    designWithBrand: challengeWithRequirements,
    numVotes
  } = taskRecord || {}
  const { rewardedWgIds, joinedNewUserChallenge } = welcomeRecord || {}
  const rewardedTaskIds = [...(rewardedDtIds || []), ...(rewardedWgIds || [])]

  const tasksJoined = [...(welcomeTasks || []), ...(dailyTasks || [])]

  const tasksFiltered = tasksJoined

  const tasksSorted = tasksFiltered.sort((a, b) => {
    return a.rank - b.rank
  })

  return tasksSorted
    .map(task => {
      const completionInfo = {}

      let actionsDone = 0

      switch (task.objectId) {
        case '1axAkTN3zc':
          actionsDone = numberLikes || 0
          break
        case 'Xr4CyGw6Jb':
          actionsDone = numberGifts || 0
          break
        case 'kmEXaEWYFG':
          actionsDone = numberFollows || 0
          break
        case 'rcIEBNmaSr':
          actionsDone = challengeWithRequirements ? 1 : 0
          break
        case 'OUv6BrETWp':
          actionsDone = designTemplate ? 1 : 0
          break
        case 'ffTFgusCfz':
          actionsDone = challenge ? 1 : 0
          break
        case 'KC4XeteGcx':
          actionsDone = joinEvent ? 1 : 0
          break
        case 'jrY1WqDHjS':
          actionsDone = numVotes || 0
          break
        case '8GORtOEbeF':
          actionsDone = rewardedTaskIds.includes('8GORtOEbeF') ? 1 : 0
          break
        case 'dIEQgLxtpw':
          actionsDone = joinedNewUserChallenge ? 1 : 0
          break
        default:
          actionsDone = 0
          break
      }

      if (rewardedTaskIds.includes(task.objectId)) {
        completionInfo.completed = true
        completionInfo.claimed = true
      } else if (
        actionsDone >=
        (Number.isInteger(task.actionsRequired) ? task.actionsRequired : 1)
      ) {
        completionInfo.completed = true
        completionInfo.claimed = false
      } else {
        completionInfo.completed = false
        completionInfo.claimed = false
      }

      return {
        ...task,
        ...completionInfo,
        actionsDone,
        actionsRequired: Number.isInteger(task.actionsRequired)
          ? task.actionsRequired
          : 1
      }
    })
    .filter(task => {
      if (['8GORtOEbeF', 'dIEQgLxtpw'].includes(task.objectId)) {
        return !task.claimed
      }

      return true
    })
}

export default determineProgressForTasks
