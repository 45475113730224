import styled from 'styled-components'

export const PostSuccessModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    align-items: flex-start;
    padding-top: 20px;
  }
`

export const PostSuccessModalBody = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 400px;
  min-height: 720px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 22px;
  overflow: hidden;

  @media (max-width: 800px) {
    width: 343px;
    min-height: 648px;
    height: 648px;
    max-height: 700px;
    padding: 0 0 22px;
  }
`

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
`

export const PostImage = styled.div`
  width: 400px;
  height: 400px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 800px) {
    width: 343px;
    height: 343px;
  }
`

export const AvatarWrapper = styled.div`
  width: 98px;
  height: 98px;
  position: relative;
  bottom: -11%;
`

export const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const PostSuccessModalInfoContent = styled.div`
  margin-top: 15%;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 9px;
  display: block;
`

export const SecondarySubtitle = styled.p`
  color: #646464;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  margin-top: 12px;
  margin-bottom: 25px;
  text-align: center;
  max-width: 271px;
`

export const EventDescription = styled.div`
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  max-width: 276px;

  & b {
    color: #ff5e6d;
    font-wight: 700;
  }

  & img {
    margin-left: 4px;
    display: inline;
    width: 14px;
    height: 14px;
    vertical-align: sub;
  }
`

export const ButtonsGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px;

  @media (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
`

export const PrimaryButton = styled.a`
  border-radius: 22px;
  background: #ff5e6d;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  width: ${({ isLarge }) => (isLarge ? '260px' : '178px')};
  height: 44px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ff5e6d;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: #fff;
    color: #ff5e6d;
  }
`

export const SecondaryButton = styled(PrimaryButton)`
  color: #ff5e6d;
  background: #fff;

  &:hover {
    background: #ff5e6d;
    color: #fff;
  }
`

export const EarningLine = styled.div`
  display: flex;
  align-items: center;
  justify-conenter: center;
  color: #ff5e6d;
  font-size: ${props => (props.big ? '18px' : '14px')};
  font-weight: 700;
  line-height: 150%;
  text-align: center;
  margin-top: 9px;
`

export const AchievementInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 263px;
  text-align: center;
`

export const LevelUpAvatarWrapper = styled.div`
  width: 98px;
  height: 98px;
  position: relative;
  transform: scale(1.3);
`

export const AchievementTitle = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const AchievementText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

export const AchievementInfo = styled.div`
  margin-bottom: -100px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export const BadgeImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  margin-top: -150px;
  margin-bottom: 100px;
`

export const EditPostButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: black;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 6px;
  background: transparent;
  border: none;
  cursor: pointer;

  & img {
    width: 18px;
    height: 18px;
  }
`

export const TutorialBadges = styled.div`
  display: flex;
  color: #ff3939;
  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 7px;

  & img {
    width: 55px;
    height: 55px;

    &:first-of-type {
      width: 65px;
      height: 65px;
    }
  }
`
