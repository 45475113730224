import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import clockImg from '../../assets/challenge-clock.svg'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useDecor } from '../../context/decor'
//import { attemptDailyTasksReset } from '../../redux/communityReducers/CheckInReducer'

const CLOCK_STYLE = {
  marginLeft: 4,
  marginRight: 4
}

const CheckInTimer = ({ UTCStartHour }) => {
  const { manualRefreshTasks } = useDecor()
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const [currentTime, setCurrentTime] = useState(new Date())
  const [needsReset, setNeedsReset] = useState(false)

  const updateTime = () => {
    setCurrentTime(new Date())
  }

  //OLD 2024 Dont know
  useEffect(() => {
    //set a timeout to update the clock and record the ID for that timeout.
    let timeoutID = setTimeout(updateTime, 200)
    // clear the timeout when CheckInTimer is unmounted.
    const cleanup = () => clearTimeout(timeoutID)
    return cleanup
  })

  if (typeof UTCStartHour !== 'number') {
    return null
  }

  let resetTime = new Date()

  if (currentTime.getUTCHours() < UTCStartHour) {
    resetTime.setUTCHours(UTCStartHour, 0, 0, 0)
  } else {
    resetTime.setUTCHours(UTCStartHour + 24, 0, 0, 0) //set for 13:00 UTC the next day
  }

  let msUntilReset = resetTime - currentTime

  if (msUntilReset < 1000) {
    setNeedsReset(true)
  } else if (needsReset && msUntilReset > 1000) {
    setNeedsReset(false)
    //OLD 2024
    //dispatch(attemptDailyTasksReset())
    //2024
    manualRefreshTasks()
    //Refresh task data when day is over
  }

  let [date, timeLeft, ms] = new Date(msUntilReset).toISOString().split(/T|\./)

  return (
    <>
      <img src={clockImg} alt="" style={CLOCK_STYLE} />
      <span style={{ color: '#FF5E6D', fontWeight: 'bold' }}>{timeLeft}</span>
    </>
  )
}

export default CheckInTimer
