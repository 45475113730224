import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import closeImg from '../../assets/exit.svg'
import {
  closeReportModal,
  sendCommentReport
} from '../../redux/communityReducers/PostActionsReducer'
import ModalOverlay from '../common/ModalOverlay'
import * as styles from '../gift/vg.module.css'

const RadioInput = ({ name, label, value, isChecked, handleChange }) => {
  const handleRadioChange = e => {
    const { id } = e.currentTarget
    handleChange(id) // Send back id to radio group for comparison
  }

  return (
    <div className={styles.radioContent}>
      <input
        type="radio"
        className={styles.radioButton}
        name={name}
        id={value}
        checked={isChecked}
        onChange={handleRadioChange}
      />
      <label htmlFor={value} className={styles.radioLabel}>
        <span>{label}</span>
      </label>
    </div>
  )
}

const ReportModal = () => {
  const dispatch = useDispatch()
  const { reportModal, reportEntity, objectId } = useSelector(
    state => state.postActions
  )
  const [selectedInput, setSelectedInput] = useState('')

  const close = () => {
    dispatch(closeReportModal())
  }

  const sendReport = id => {
    setSelectedInput('')
    if (objectId.reportEntity === 'comment') {
      dispatch(sendCommentReport(objectId.objectId, id))
    } else {
      dispatch(sendReport(objectId, id))
    }
  }

  let reportReason = [
    'I just don’t like it',
    'Nudity or pornography',
    'Hate speech or symbols',
    'Sale or promotion of drugs',
    'Harassment or bullying',
    'Intellectual property violation',
    'Self injury'
  ]

  const handleChange = inputValue => {
    setSelectedInput(inputValue)
  }
  let report = reportReason.map((item, index) => (
    <RadioInput
      key={index}
      name="option"
      value={item}
      label={item}
      isChecked={selectedInput === item}
      handleChange={handleChange}
    />
  ))

  const useOutsideAlerter = (ref, closeMenu, active) => {
    const dispatch = useDispatch()

    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(closeMenu())
      }
    }

    useEffect(() => {
      if (active) document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    })
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, closeReportModal, reportModal)

  if (!reportModal) {
    return null
  }
  return (
    <ModalOverlay>
      <div className={styles.reportModal} ref={wrapperRef}>
        <div>
          <img
            src={closeImg}
            alt=""
            className={styles.closeButton}
            onClick={close}
          />
          <span className={styles.reportTitle}>
            Report {reportEntity === 'comment' ? 'Comment' : 'Design'}
          </span>
        </div>
        <div className={styles.reportContent}>{report}</div>
        <button
          className={classNames(styles.submitButton, {
            [styles.submitButtonActive]: selectedInput
          })}
          onClick={() => sendReport(selectedInput)}
        >
          Submit
        </button>
      </div>
    </ModalOverlay>
  )
}
export default ReportModal
