import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'

import styled from 'styled-components'

import { claimWelcomeGiftReward, setShowUsernameModal } from '../../redux/communityReducers/CheckInReducer'
import bg from '../../assets/create-username.png'
import closeIcon from '../../assets/close.svg'
import { lookupUniqueName } from '../../redux/apis'
import { saveUsername } from '../../routes/Profile/store/profileReducer'
import useDailyTasks from '../../hooks/useDailyTasks'

const regexp = /\W|_/g

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 1000;

  @media (max-width: 1200px) {
    margin-top: 60px;
    z-index: 899;
    height: calc(100vh - 60px);
  }
`

const Dimmer = styled(Container)`
  background: rgba(0, 0, 0, 0.6);
`

const ModalContainer = styled.div`
  position: relative;
  height: 528px;
  width: 520px;

  max-width: 90vw;

  border-radius: 10px;
  background-color: #fff;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;

  overflow: hidden;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    height: auto;
    min-height: 462px;
  }
`

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  z-index: 100;
  padding: 0;

  & img {
    width: 19px;
    height: 19px;
  }
`

const Content = styled.div`
  width: 340px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (max-width: 1000px) {
    max-width: 229px;
  }
`

const Title = styled.h2`
  font-family: Helvetica Neue;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  margin: 0 0 70px 0;
  padding: 0;
  color: #000000;

  @media (max-width: 1000px) {
    font-size: 18px;
    margin-bottom: 35px;
  }
`

export const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #000000;
  background: transparent;
  height: 36px;
  text-align: center;
  border-radius: 0;

  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;

  margin-bottom: 25px;
`

const Requirement = styled.span`
  display: block;
  font-family: Helvetica Neue;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  display: flex;
  width: 100%;
  white-space: nowrap;
  color: ${({ color }) => color};
  text-align: left;
  margin-bottom: 12px;

  & svg {
    width: 14px;
    height: 14px;
    margin-right: 7px;
    flex-shrink: 0;
  }

  @media (max-width: 1000px) {
    white-space: normal;
    font-size: 12px;
    margin-bottom: 7px;
  }
`

const Unavailable = styled.span`
  font-family: Helvetica Neue;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ff5e6d;
`

const Submit = styled.button`
  height: 44px;
  width: 260px;
  border-radius: 22px;
  background: #ff5e6d;
  border: 0;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  margin-top: 100px;
  cursor: pointer;

  @media (max-width: 1000px) {
    margin-top: 41px;
  }
`

const ExistsContainer = styled.div`
  position: absolute;
  top: 140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    top: 105px;
  }
`

const SuggestionsContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 4px 9px 0px #00000040;
`

const Suggestion = styled.button`
  width: 100%;
  font-family: Helvetica Neue;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  padding: 10px 11px 10px 18px;
  border: 0;
  background: transparent;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid #d8d8d8;
  }
`

export const CreateUsernameModal = () => {
  const { openDailyTask } = useDailyTasks()
  const { showUsernameModal } = useSelector(state => state.checkIn)
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isExists, setExists] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [errors, setErrors] = useState({
    length: false,
    lowercase: false,
    specialSymbols: false
  })

  const handleCheckName = useRef(
    debounce(async value => {
      setLoading(true)
      const { result } = await lookupUniqueName(value)
      setLoading(false)
      setExists(result.exists)
      if (result.suggestions) {
        setSuggestions(result.suggestions)
      }
    }, 1000)
  ).current

  useEffect(() => {
    return () => {
      handleCheckName.cancel()
    }
  }, [handleCheckName])

  const handleChange = e => {
    const { value } = e.target
    setName(value)
    setExists(false)
    setSuggestions([])

    const length = value.length < 5 || value.length >= 15
    const lowercase = value !== value.toLowerCase()
    const specialSymbols = regexp.test(value)

    setErrors({
      length,
      lowercase,
      specialSymbols
    })

    if (value.length > 3 && !lowercase && !specialSymbols) {
      handleCheckName(value)
    }
  }

  const handleClose = () => {
    setName('')
    setExists(false)
    setSuggestions([])
    setErrors({})
    dispatch(setShowUsernameModal(false))
  }

  const handleSubmit = () => {
    if (isLoading) return
    if (!errors.length && !errors.lowercase && !errors.specialSymbols && !!name && !isExists) {
      setLoading(true)
      dispatch(saveUsername(name)).then(async () => {
        await dispatch(claimWelcomeGiftReward('8GORtOEbeF'))
        await openDailyTask()
        //dispatch(resetAndOpen())
        handleClose()
      })
    } else {
      setExists(true)
    }
  }

  const lengthColor = !name ? '#979797' : errors.length ? '#ff5e6d' : '#3AAC66'
  const lowercaseColor = !name ? '#979797' : !errors.lowercase && !errors.specialSymbols ? '#3AAC66' : '#ff5e6d'

  if (!showUsernameModal) return null

  return (
    <Container>
      <Dimmer />
      <ModalContainer>
        <CloseButton onClick={handleClose}>
          <img src={closeIcon} alt="Close" />
        </CloseButton>
        <Content>
          <Title>Create Username</Title>
          <Input placeholder="Enter username" value={name} onChange={handleChange} />
          <ExistsContainer>
            {isExists && <Unavailable>The username is not available.</Unavailable>}
            {suggestions.length > 0 && (
              <SuggestionsContainer>
                {suggestions.map(suggestion => (
                  <Suggestion
                    onClick={() => {
                      setName(suggestion)
                      setSuggestions([])
                      setExists(false)
                      setErrors({})
                    }}
                  >
                    {suggestion}
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="8.5" stroke="#3AAC66" />
                      <path d="M12.9887 6.22584C13.2748 6.5221 13.2694 6.99694 12.9766 7.28643L8.42554 11.7864C8.13754 12.0712 7.67755 12.0712 7.38955 11.7864L5.02298 9.44643C4.73021 9.15694 4.72479 8.6821 5.01087 8.38584C5.29695 8.08958 5.7662 8.08409 6.05898 8.37358L7.90754 10.2014L11.9406 6.21358C12.2334 5.92409 12.7027 5.92958 12.9887 6.22584Z" fill="#3AAC66" />
                    </svg>
                  </Suggestion>
                ))}
              </SuggestionsContainer>
            )}
          </ExistsContainer>
          <Requirement color={lengthColor}>
            <svg width="14" height="14" viewBox="0 0 14 14">
              <circle cx="7" cy="7" r="7" fill={lengthColor} />
              <path d="M9.84924 5.1882C10.0536 5.43508 10.0497 5.83078 9.84059 6.07202L6.58981 9.82202C6.3841 10.0593 6.05553 10.0593 5.84982 9.82202L4.15941 7.87202C3.95029 7.63078 3.94641 7.23508 4.15076 6.9882C4.3551 6.74131 4.69029 6.73674 4.89941 6.97798L6.21981 8.50116L9.10059 5.17798C9.30971 4.93674 9.6449 4.94131 9.84924 5.1882Z" fill="white" />
            </svg>
            Use 6 or more characters with a mix of letters & numbers.
          </Requirement>
          <Requirement color={lowercaseColor}>
            <svg width="14" height="14" viewBox="0 0 14 14">
              <circle cx="7" cy="7" r="7" fill={lowercaseColor} />
              <path d="M9.84924 5.1882C10.0536 5.43508 10.0497 5.83078 9.84059 6.07202L6.58981 9.82202C6.3841 10.0593 6.05553 10.0593 5.84982 9.82202L4.15941 7.87202C3.95029 7.63078 3.94641 7.23508 4.15076 6.9882C4.3551 6.74131 4.69029 6.73674 4.89941 6.97798L6.21981 8.50116L9.10059 5.17798C9.30971 4.93674 9.6449 4.94131 9.84924 5.1882Z" fill="white" />
            </svg>
            No capital letters, spaces, or symbols.
          </Requirement>
          <Submit onClick={handleSubmit}>Save</Submit>
        </Content>
      </ModalContainer>
    </Container>
  )
}
