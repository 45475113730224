import React, { useCallback, useEffect, useState } from 'react'
import {
  AvatarImage,
  AvatarWrapper,
  BackButton,
  Divider,
  LevelMark,
  ModalWindow,
  ModalWrapper,
  Username,
  VoteCard,
  VoteCardFooter,
  VoteCardHeader,
  VoteCardImage,
  VoteCardOverlay,
  VoteCounter,
  VoteInnerProgressBar,
  VoteOverlayText,
  VoteProgressBar
} from './VoteModal.styles'
import Portal from '../DesignerCommon/portal'
import { useSelector } from 'react-redux'
import likeImg from '../../assets/voting/like.svg'
import dislikeImg from '../../assets/voting/dislike.svg'
import arrowImg from '../../assets/voting/arrow.svg'
import { useVotingPosts, voteForDesign } from '../../queries/vote'
import Link from 'next/link'
import { getUserAvatar } from '../../utils/getUserAvatar'

const VoteCandidateCard = ({ liked, showOverlay, onClick, post }) => {
  return (
    <VoteCard
      liked={liked}
      onClick={() => (showOverlay || liked ? null : onClick(post))}
    >
      <VoteCardImage src={post?.idea?.cfThumbImageUrl} alt="" />
      {showOverlay && (
        <VoteCardOverlay>
          {liked && (
            <VoteCardHeader>
              <VoteOverlayText>You voted for</VoteOverlayText>
              <a
                target="_blank"
                href={`/dm/${post?.user?.objectId}?showDailyRewards=false`}
              >
                <AvatarWrapper>
                  <AvatarImage src={getUserAvatar(post?.user)} />
                  <LevelMark>L{Math.floor(post?.user?.level || 1)}</LevelMark>
                </AvatarWrapper>
              </a>
              <Username>
                {post?.user?.uniqueDisplayName || post?.user?.name || ''}
              </Username>
            </VoteCardHeader>
          )}
          <VoteCardFooter>
            <img src={liked ? likeImg : dislikeImg} alt="" />
            <VoteOverlayText>
              {post?.numLikes ? post?.numLikes + 1 : liked ? 1 : 0} Liked
            </VoteOverlayText>
          </VoteCardFooter>
        </VoteCardOverlay>
      )}
    </VoteCard>
  )
}

const TOTAL_STEPS = 15

export const VoteModal = ({ onClose }) => {
  const numVotes =
    useSelector(state => state.checkIn?.taskRecord?.numVotes) || 0
  const [currentStep, setCurrentStep] = useState(numVotes)
  const designsData = useVotingPosts(currentStep)
  const [votedFor, setVotedFor] = useState(null)

  const completionPercentage = ((currentStep + 1) / TOTAL_STEPS) * 100

  const handleVote = useCallback(
    votedFor => {
      setVotedFor(votedFor)
      voteForDesign(votedFor.objectId)

      setTimeout(() => {
        setVotedFor(null)
        const nextStep = currentStep + 1

        if (nextStep >= TOTAL_STEPS) {
          onClose()
        } else {
          setCurrentStep(nextStep)
        }
      }, 2000)
    },
    [onClose, currentStep]
  )

  const nextStepNumber = currentStep > 15 ? 15 : currentStep

  return (
    <Portal>
      <ModalWrapper>
        <ModalWindow>
          <BackButton onClick={onClose}>
            <img src={arrowImg} alt="" />
          </BackButton>
          <VoteCounter>
            {nextStepNumber} / {TOTAL_STEPS}
          </VoteCounter>
          <VoteProgressBar>
            <VoteInnerProgressBar percent={completionPercentage} />
          </VoteProgressBar>
          <Divider />
          {designsData?.data?.userInspirations && (
            <>
              <VoteCandidateCard
                liked={votedFor === designsData?.data?.userInspirations[0]}
                post={designsData?.data?.userInspirations[0]}
                showOverlay={!!votedFor}
                onClick={handleVote}
              />
              <VoteCandidateCard
                liked={votedFor === designsData?.data?.userInspirations[1]}
                post={designsData?.data?.userInspirations[1]}
                showOverlay={!!votedFor}
                onClick={handleVote}
              />
            </>
          )}
        </ModalWindow>
      </ModalWrapper>
    </Portal>
  )
}
