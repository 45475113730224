import styled from 'styled-components'

export const CommonFlex = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

export const ModalWrapper = styled(CommonFlex)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  justify-content: center;

  @media (max-width: 800px) {
    height: var(--app-height);
  }
`

export const ModalWindow = styled.div`
  overflow: hidden;
  height: 840px;
  width: 600px;
  border-radius: 16px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  position: relative;

  @media (max-width: 800px) {
    width: 100vw;
    height: var(--app-height);
    border-radius: 0;
    overflow: auto;
    padding-top: 8px;
  }
`

export const VoteCounter = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  dispaly: block;

  @media (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
  }
`

export const VoteProgressBar = styled.div`
  width: 344px;
  height: 12px;
  background: #efefef;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  margin-top: 9px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    width: 115px;
    margin-top: 4px;
    margin-bottom: 12px;
    height: 10px;
  }
`

export const VoteInnerProgressBar = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #ff5e6d;
  width: ${props => props.percent || 0}%;
  border-radius: 25px;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #efefef;
  content: '';
  margin-bottom: 24px;
`

export const VoteCard = styled.div`
  max-width: 344px;
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  border: 4px solid ${props => (props.liked ? '#ff5e6d' : 'transparent')};
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;

  &:last-child {
    margin-top: 16px;
    margin-bottom: 20px;
  }
`

export const VoteCardImage = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  overflow: hidden;
`

export const VoteCardOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`

export const VoteOverlayText = styled.span`
  dispaly: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`

export const AvatarContainer = styled.div`
  display: flex;
`

export const AvatarWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #f1f1f1;
  position: relative;
  background: #f1f1f1;
`

export const AvatarImage = styled.img`
  width: 15vmin;
  height: 15vmin;
  max-width: 74px;
  max-height: 74px;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box;
  border-radius: 50%;
  border: 3px solid #ffffff;
`

export const LevelMark = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: #ff5e6d;
  border-radius: 9px;
  height: 17px;
  font-weight: 500;
  font-size: 8px;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
`

export const Username = styled.span`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: -5px;
`

export const VoteCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 29px;
`

export const VoteCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;

  & img {
    max-width: 50px;
    max-height: 50px;
    width: 8vmin;
    height: 8vmin;
  }

  @media (max-width: 800px) {
    gap: 3vmin;
  }
`

export const BackButton = styled.button`
  position: absolute;
  top: 27px;
  left: 23px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    top: 10px;
    left: 14px;
  }
`
