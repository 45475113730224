import styled from 'styled-components'

export const FilterModalContainer = styled.div`
  width: 622px;
  height: 545px;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  padding: 36px 48px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100vw;
    height: 55vh;
    padding: 38px 26px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

export const CloseIcon = styled.img`
  position: absolute;
  top: 12px;
  right: 16px;
`

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const UsesTag = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #ff5e6d;
  background: #ffe5e5;
  border-radius: 180px;
  padding: 9px 12px;
  display: block;
  margin-left: 28px;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
    padding: 7px 10px;
    margin-left: 16px;
  }
`

export const ComparationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 35px;
`

export const ComparationItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;

  @media (max-width: 768px) {
    width: 49%;
  }
`

export const ComparationLabel = styled.span`
  display: block;
  margin-top: 16px;
  color: ${props => (props.pale ? '#8B8B8B' : 'black')};
  letter-spacing: 0.01em;
  font-size: 18px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
    margin-top: 14px;
  }
`

export const UseButton = styled.button`
  background: linear-gradient(
    90deg,
    #ff5e6d 0%,
    #ff4f7e 36.46%,
    #ff5e6d 68.23%,
    #ff9f74 100%
  );
  border-radius: 30px;
  height: 48px;
  width: 290px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & span {
    display: block;
    flex-grow: 1;
    margin: 0 20px;
  }
`

export const MemberCircle = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 29px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  height: 140px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: auto;
    margin-top: 44px;
  }
`
