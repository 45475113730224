import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import coinImg from '../../../assets/coin.svg'
import closeImg from '../../../assets/exit.svg'
import { setShowPurchaseCoins } from '../../../redux/designerReducers/designerReducer'
import {
  closeModals,
  closeSuccessModal,
  getUserInsGifts,
  getVirtualGifts,
  sendGift1
} from '../../../redux/communityReducers/GiftReducer'
import { loadMe } from '../../../routes/Profile/store/profileReducer'
import * as styles from '../vg.module.css'

import GiftSuccessScreen from './GiftSuccessScreen'
import GiftCard from './GiftCard'

// slide in animation
const slideIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`

const Modal = styled.div`
  width: 680px;
  height: 680px;
  background-color: #f5f5f5;
  border-radius: 20px;
  position: relative;

  &:-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &:-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 46px;
  }

  @media screen and (max-width: 700px) {
    height: 75vh;
    bottom: 0;
    overflow-y: hidden;
    align-self: flex-end;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    animation: ${slideIn} 0.4s ease;
  }
`

const GiftList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 24px 15px;
  height: 558px;
  overflow-y: scroll;
  padding-bottom: 64px;
  box-sizing: border-box;

  @media screen and (max-width: 700px) {
    margin: 20px 10px;
    padding-bottom: 190px;
  }
`

const VirtualGiftModal = () => {
  const dispatch = useDispatch()
  const { virtualGiftList, giftModalOpen, userInsIds, giftSuccessOpen } =
    useSelector(state => state.gift)
  const [selectedId, setSelectedId] = useState(null)
  const [successId, setSuccessId] = useState(null)
  const [selectCoins, setSelectCoins] = useState(0)
  const me = useSelector(state => state.profile.me)
  const [hoverId, setHoverId] = useState(null)

  useEffect(() => {
    dispatch(getVirtualGifts())
  }, [dispatch])

  const useOutsideAlerter = (ref, closeMenu, active) => {
    const dispatch = useDispatch()
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(closeMenu())
        document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
      }
    }

    useEffect(() => {
      if (active) document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    })
  }

  const wrapperRef = useRef(null)

  const sendGift = (selectedId, selectCoins) => {
    if (selectCoins > me.numCoins) {
      dispatch(closeModals())
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
      dispatch(setShowPurchaseCoins(true))
    } else {
      if (selectedId != null) {
        dispatch(sendGift1(userInsIds, selectedId))
        dispatch(loadMe({ history: {} }))
        dispatch(getUserInsGifts(userInsIds))
      }
      setSuccessId(selectedId)
    }
  }

  const setSelectedIdCoins = (id, coins) => {
    setSelectCoins(coins)
    setSelectedId(id)
  }

  const handleClose = () => {
    setSelectedId(null)
    dispatch(closeSuccessModal())
    dispatch(closeModals())
  }

  useOutsideAlerter(wrapperRef, closeModals, giftModalOpen)

  if (!giftModalOpen) {
    return null
  } else if (giftSuccessOpen && virtualGiftList) {
    return (
      <GiftSuccessScreen
        url={virtualGiftList.find(gift => gift.objectId === successId).gifUrl}
        close={handleClose}
      />
    )
  }

  let gifts = []

  if (virtualGiftList) {
    gifts = virtualGiftList.map(gift => (
      <GiftCard
        title={gift.title}
        url={gift.thumbImageUrl}
        gifUrl={gift.gifUrl}
        coins={gift.numCoins}
        key={gift.objectId}
        selected={gift.objectId === selectedId}
        hovered={gift.objectId === hoverId}
        onMouseEnter={() => setHoverId(gift.objectId)}
        onMouseLeave={() => setHoverId(null)}
        onClick={() => setSelectedIdCoins(gift.objectId, gift.numCoins)}
      />
    ))
  }

  return (
    <Overlay>
      <Modal>
        <img
          src={closeImg}
          alt="Close"
          className={styles.closeButton}
          onClick={handleClose}
        />
        <div style={{ display: 'flex' }}>
          <div className={styles.giftHeader}>Send Gifts</div>
          <img
            src={coinImg}
            alt=""
            style={{ height: 24, width: 24, marginTop: 68, marginLeft: 'auto' }}
          />
          <div className={styles.coinBalance}>Balance: {me.numCoins}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <GiftList>{gifts}</GiftList>
        </div>
        <button
          className={styles.sendButton}
          disabled={!selectedId}
          onClick={() => sendGift(selectedId, selectCoins)}
        >
          {selectCoins > me.numCoins ? 'Refill' : 'Send'}
        </button>
      </Modal>
    </Overlay>
  )
}

export default VirtualGiftModal
