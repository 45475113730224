import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { updatePost } from '../../redux/communityReducers/PostActionsReducer'
import closeImg from '../../assets/close.svg'
import { useRouter } from 'next/router'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 744px) {
    background: transparent;
    align-items: start;
  }
`

const Root = styled.div`
  height: min(765px, 98vh);
  width: min(504px, 95vw);
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  border-radius: 10px;
  z-index: 1000;
  flex-direction: column;

  @media only screen and (max-width: 744px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`

const TitleRow = styled.div`
  font-family: Helvetica Neue;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 28px;

  @media only screen and (max-width: 744px) {
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0.16px;
    padding: 25px 13px 20px;
  }
`

const ImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 27px;

  & > img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }

  @media only screen and (max-width: 744px) {
    padding: 0;
  }
`

const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 27px 23px;
  justify-content: space-between;
  flex-grow: 1;

  @media only screen and (max-width: 744px) {
    padding: 12px 13px 10vh;
  }
`

const Input = styled.textarea`
  width: 100%;
  height: 166px;
  border-radius: 5px;
  resize: none;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;

  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 7px 14px;
  outline: none;

  @media only screen and (max-width: 744px) {
    flex-grow: 1;
  }
`

const Submit = styled.button`
  width: 280px;
  height: 40px;
  border-radius: 110px;
  background-color: #ff5e6d;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.01em;
  border: 0;
  color: #fff;
  margin: 0 auto;
  сursor: pointer;
  margin-top: 14px;

  &:disabled {
    background-color: #c4c4c4;
  }
`

const Close = styled.button`
  width: 28px;
  height: 28px;
  margin-left: auto;
  border: 0;
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > img {
    width: 20px;
    height: 20px;
  }
`

const CaptionModal = ({ data, handleClose }) => {
  const [value, setValue] = useState(data.title || '')
  const router = useRouter()
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(updatePost({ objectId: data.objectId, title: value }))
    router.push(`/design/${data.objectId}`)
    handleClose()
  }

  return (
    <Overlay>
      <Root>
        <TitleRow>
          Edit Info
          <Close onClick={handleClose}>
            <img src={closeImg} alt="close" />
          </Close>
        </TitleRow>
        <ImageRow>
          <img src={data.idea.cfThumbImageUrl} alt="caption" />
        </ImageRow>
        <ContentRow>
          <Input
            value={value}
            onChange={e => {
              setValue(e.target.value)
            }}
          />
          <Submit
            disabled={(data.title || '') === value}
            onClick={handleSubmit}
          >
            Done
          </Submit>
        </ContentRow>
      </Root>
    </Overlay>
  )
}

export default CaptionModal
