import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import deleteQuestionImg from '../../assets/modal/deleteQuestion.svg'
import {
  deletePost,
  hideDeletionModal
} from '../../redux/communityReducers/PostActionsReducer'
import { fetchChallenges } from '../../redux/designerReducers/challengeReducer'
import ModalOverlay from '../common/ModalOverlay'
import {
  DeleteConfirmationModalContainer,
  DeleteConfirmationText,
  DeleteConfirmationTitle
} from './DeleteConfirmationModal.styles'

const DMPrimaryButton = dynamic(
  () =>
    import('@decormatters/dm-theme').then(
      component => component.DMPrimaryButton
    ),
  { ssr: false }
)

const DMPrimaryAltButton = dynamic(
  () =>
    import('@decormatters/dm-theme').then(
      component => component.DMPrimaryAltButton
    ),
  { ssr: false }
)

export const DeleteConfirmationModal = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const showDeleteModal = useSelector(
    state => state.postActions.showDeleteModal
  )
  const postIdToDelete = useSelector(state => state.postActions.postIdToDelete)
  const deletingChallengePost = useSelector(
    state => state.postActions.deletingChallengePost
  )

  if (!showDeleteModal) return null

  return (
    <ModalOverlay>
      <DeleteConfirmationModalContainer>
        <img src={deleteQuestionImg} alt="" />
        <DeleteConfirmationTitle>Delete your post?</DeleteConfirmationTitle>
        <DeleteConfirmationText>
          {deletingChallengePost
            ? 'Are you sure you want to delete the challenge design? Your design, likes, comments, and gifts for the design will be permanently deleted. If the challenge is still live, you may submit another design.'
            : 'Are you sure you want to delete the design? Your design, likes, comments, and gifts for the design will be permanently deleted.'}
        </DeleteConfirmationText>
        <div>
          <DMPrimaryAltButton
            onClick={() => {
              dispatch(hideDeletionModal())
            }}
          >
            Cancel
          </DMPrimaryAltButton>
          <DMPrimaryButton
            onClick={() => {
              dispatch(deletePost({ postId: postIdToDelete }))
              dispatch(hideDeletionModal())

              setTimeout(() => {
                router.replace('/community/explore')
              }, 100)
            }}
          >
            Delete
          </DMPrimaryButton>
        </div>
      </DeleteConfirmationModalContainer>
    </ModalOverlay>
  )
}
