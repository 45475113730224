import { useQuery } from 'react-query'
import {
  APPID,
  GET_USER_INSPIRATIONS,
  GET_VOTING_POSTS,
  VOTE_FOR_POST
} from '../constants/ApiEndpoints'
import { requestHelper } from '../utils/reduxActionUtils'

export const useVotingPosts = (step = 0) =>
  useQuery(['GET_VOTING_POSTS', step], async () => {
    const params = requestHelper({})
    const res = await fetch(GET_VOTING_POSTS, params)
    const { result } = await res.json()

    return result
  })

export const voteForDesign = async designId => {
  const result = await fetch(
    VOTE_FOR_POST,
    requestHelper({
      userInspirationObjectId: designId
    })
  )

  return await result.json()
}
