import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import memberImg from '../../assets/badge_membership_on.png'
import closeIconImg from '../../assets/exit.svg'
import { setShowPurchaseCoins } from '../../redux/designerReducers/designerReducer'
import { setFilterInfoModalVisible } from '../../redux/communityReducers/PostActionsReducer'
import ModalOverlay from '../common/ModalOverlay'
import DesignCanvas from '../DesignCanvas'
import {
  ButtonContainer,
  CloseIcon,
  ComparationContainer,
  ComparationItem,
  ComparationLabel,
  FilterModalContainer,
  MemberCircle,
  ModalTitle,
  UseButton,
  UsesTag
} from './FilterInfoModal.styles'
import { useRouter } from 'next/router'
import { gotoLogIn } from '../../redux/actions/AuthActions'
import { getMembershipType } from '../../utils/getMembershipType'

export const FilterInfoModal = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { showFilterInfoModal, filterInfoPost } = useSelector(
    state => state.postActions
  )
  const user = useSelector(state => state?.profile?.me)

  const { isMember } = getMembershipType(user)

  if (!showFilterInfoModal) return null

  const handleClose = () => {
    dispatch(
      setFilterInfoModalVisible({
        visible: false,
        post: {}
      })
    )
  }

  const { animationInfo } = filterInfoPost

  return (
    <ModalOverlay fullscreen bottomSheet onClose={handleClose}>
      <FilterModalContainer onClick={e => e.preventDefault()}>
        <CloseIcon onClick={handleClose} src={closeIconImg} alt="" />
        <ModalTitle>
          <span>{animationInfo.title}</span>
          {animationInfo.numUses && (
            <UsesTag>{animationInfo.numUses} Used</UsesTag>
          )}
        </ModalTitle>
        <ComparationContainer>
          <ComparationItem>
            <DesignCanvas
              scalable
              data={filterInfoPost}
              displayAnimation={false}
              displayRichContent={false}
            />
            <ComparationLabel pale>Before</ComparationLabel>
          </ComparationItem>
          <ComparationItem>
            <DesignCanvas
              scalable
              data={filterInfoPost}
              displayRichContent={false}
            />
            <ComparationLabel>After</ComparationLabel>
          </ComparationItem>
        </ComparationContainer>
        <ButtonContainer>
          {!isMember ? (
            <UseButton
              onClick={() => {
                handleClose()
                dispatch(setShowPurchaseCoins(true))
              }}
            >
              <MemberCircle>
                <img src={memberImg} alt="" />
              </MemberCircle>
              <span>Unlock and Use the Filter</span>
            </UseButton>
          ) : (
            <UseButton
              onClick={() => {
                if (isMember) {
                  router.push('/')
                } else {
                  dispatch(gotoLogIn())
                }
                handleClose()
              }}
            >
              Use the Filter
            </UseButton>
          )}
        </ButtonContainer>
      </FilterModalContainer>
    </ModalOverlay>
  )
}
