import React from 'react'
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton
} from 'react-share'
import copyLinkImg from '../../assets/CopyLink.svg'
import fbIconImg from '../../assets/social/fb.svg'
import pinsIconImg from '../../assets/social/pins.svg'
import twitterIconImg from '../../assets/social/twitter.svg'
import {
  SOCIAL_MEDIA_BLOG_URL,
  SOCIAL_MEDIA_SHARE_URL
} from '../../constants/ApiEndpoints'
import { logEvent } from '../../utils/firebase'
import * as styles from './sharemedia.module.css'

export const ShareMedia = props => {
  //const shareUrl = window.location.href + `design/${props.id}`
  const shareUrl = !props.customUrl
    ? SOCIAL_MEDIA_SHARE_URL + props.id
    : SOCIAL_MEDIA_BLOG_URL + props.customUrl

  const shareClick = e => {
    e.stopPropagation()
    // const currentUrl = new URL(window.location.href)

    const newClipboard = document.createElement('textarea')
    // and give it some content
    newClipboard.value = shareUrl
    document.body.appendChild(newClipboard)
    newClipboard.select()
    document.execCommand('copy')
    document.body.removeChild(newClipboard)
    alert(
      'Url is copied to clipboard! Feel free to share this inspiration to others!'
    )

    handleShare('Link')
  }

  const textGenerator = text => {
    if (window.innerWidth > 600) {
      return text
    }

    return 'Share to ' + text
  }

  const handleShare = method => {
    if (props.customUrl) {
      logEvent('share', {
        content_type: 'blog',
        content_id: props.customUrl,
        method: method
      })
    } else {
      logEvent('share', {
        content_type: 'inspiration',
        content_id: props.id,
        method: method
      })
    }
  }

  const getShareTitle = () => {
    switch (props.shareSrc) {
      case 'blog':
        return 'Take a look at this blog! '
      default:
        return 'Check Out This Design! '
    }
  }

  return (
    <>
      <div
        className={`${styles.socialNetworkContainer} ${
          props.type === 'box' ? styles.box : ''
        }`}
      >
        <ul>
          <li>
            <PinterestShareButton
              className={styles.socialComponentContainer}
              url={shareUrl}
              media={props.imgUrl}
              onClick={() => handleShare('Pinterest')}
            >
              <div className={styles.icon}>
                <img src={pinsIconImg} alt="" className={styles.pins} />
              </div>

              <span
                className={`${
                  props.type === 'box'
                    ? styles.mediaTextActive
                    : styles.mediaText
                }`}
              >
                {textGenerator('Pinterest')}
              </span>
            </PinterestShareButton>
          </li>
          <li>
            <TwitterShareButton
              className={styles.socialComponentContainer}
              title={getShareTitle()}
              url={shareUrl}
              onClick={() => handleShare('Twitter')}
            >
              <div className={styles.icon}>
                {' '}
                <img src={twitterIconImg} alt="" className={styles.twtr} />
              </div>

              <span
                className={`${
                  props.type === 'box'
                    ? styles.mediaTextActive
                    : styles.mediaText
                }`}
              >
                {textGenerator('Twitter')}
              </span>
            </TwitterShareButton>
          </li>
          <li>
            <FacebookShareButton
              className={styles.socialComponentContainer}
              url={shareUrl}
              quote={getShareTitle()}
              onClick={() => handleShare('Facebook')}
            >
              <div className={styles.icon}>
                <img src={fbIconImg} alt="" className={styles.fb} />
              </div>

              <span
                className={`${
                  props.type === 'box'
                    ? styles.mediaTextActive
                    : styles.mediaText
                }`}
              >
                {textGenerator('Facebook')}
              </span>
            </FacebookShareButton>
          </li>
          <li className={styles.copyLink} onClick={shareClick}>
            <button aria-label={'copy-link'}>
              <div className={styles.icon}>
                <img
                  src={copyLinkImg}
                  alt=""
                  className={styles.copyLinkIcon}
                  onClick={shareClick}
                />
              </div>

              <div
                className={`${
                  props.type === 'box'
                    ? styles.mediaTextActive
                    : styles.mediaText
                }`}
              >
                Copy Link
              </div>
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default ShareMedia
