import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  AchievementInfo,
  AchievementInfoContainer,
  AchievementText,
  AchievementTitle,
  AvatarWrapper,
  BadgeImage,
  ButtonsGrid,
  CanvasWrapper,
  CloseButton,
  EarningLine,
  EditPostButton,
  EventDescription,
  HeaderRow,
  LevelUpAvatarWrapper,
  PostImage,
  PostSuccessModalBody,
  PostSuccessModalInfoContent,
  PostSuccessModalWrapper,
  PrimaryButton,
  SecondaryButton,
  SecondarySubtitle,
  TutorialBadges,
  Title
} from './PostSuccessModal.styles'
import { DesignCanvas } from '../DesignCanvas/DesignCanvas'
import ProfileCircle from '../DesignerProfile/ProfileCircle'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAvatar } from '../../utils/getUserAvatar'
import Link from 'next/link'
import coinImg from '../../assets/coin.svg'
import { ReactComponent as CoinIcon } from '../../assets/coin.svg'
import { ReactComponent as ExpIcon } from '../../assets/exp_big.svg'
import newUserBadge from '../../assets/new-user-badge.png'
import editPostImg from '../../assets/editPost.svg'
import closeImg from '../../assets/close.svg'
import winBadgeIcon from '../../assets/userEvent/winBadge.svg'
import { useRouter } from 'next/router'
import { setLocationState } from '../../redux/designerReducers/locationStateReducer'
import { CaptionModal } from '../CaptionModal'
import { BadgeProgress } from '../DesignerPost/PostProfile'
import { setNavbar } from '../../redux/designerReducers/designerReducer'
import { NAV } from '../../redux/constants'
import manyCoins from '../../assets/Coins.svg'
import { clearFilters } from '../../redux/designerReducers/itemsReducer'
import { setSelectedBubble } from '../../redux/designerReducers/canvasReducer'

const DISPLAY_MODE = {
  POST_INFO: 'POST_INFO',
  LEVEL_UP: 'LEVEL_UP',
  BADGE_PROGRESS: 'BADGE_PROGRESS',
  BADGE: 'BADGE'
}

export const PostSuccessModal = () => {
  const { state: locationState } = useSelector(state => state.locationState)
  const [showEditModal, setShowEditModal] = useState(false)

  const {
    badgeReward: badge,
    startLevel,
    endLevel,
    savedUserInspiration,
    exp,
    coinIncrease,
    userEventCoinReward
  } = locationState?.data || {}

  const rProfileCircle = useRef()
  const router = useRouter()
  const profile = useSelector(state => state?.profile?.me)
  const dispatch = useDispatch()

  const picture = getUserAvatar(profile)

  const [displayMode, setDisplayMode] = useState(DISPLAY_MODE.POST_INFO)
  const [badgeStatus, setBadgeStatus] = useState()
  const [badgeReward, setBadgeReward] = useState()
  const [step, setStep] = useState(0)
  const [isLevelUp, setIsLevelUp] = useState(false)
  const [isTutorial, setIsTutorial] = useState(false)
  const [sLevel, setSlevel] = useState()
  const [eLevel, setElevel] = useState()
  const [userEvent, setUserEvent] = useState(null)
  const [challenge, setChallenge] = useState(null)

  const reset = useCallback(d => {
    setIsLevelUp(false)
    setSlevel(0)
    setElevel(0)
  }, [])

  useEffect(() => {
    setUserEvent(savedUserInspiration?.userEvent)
    setChallenge(savedUserInspiration?.designContest)
    setIsTutorial(savedUserInspiration?.type === 'tutorial')

    if (!badge) {
      setBadgeStatus()
      return
    }

    setBadgeStatus(badge.status)

    const p = badge.numPosts || 0
    const r = badge.badge.numPostsRequired || 0

    setStep(r - p)

    if (badge) {
      var br = { ...badge }
      if (!badge.badge.title && savedUserInspiration?.designContest?.badge) {
        br = {
          ...br,
          badge: {
            ...savedUserInspiration?.designContest?.badge
          }
        }
      }

      setBadgeReward(br)
    }
  }, [badge, savedUserInspiration])

  useEffect(() => {
    reset()
    if (!profile) return

    var slvl = 0
    var elvl = 0
    if (!startLevel && !endLevel) {
      slvl = profile.userLevel
      elvl = profile.userLevel
      setSlevel(slvl)
      setElevel(elvl)
    } else {
      slvl = startLevel
      elvl = endLevel

      setSlevel(startLevel)
      setElevel(endLevel)
    }

    if (!slvl) return
    const dif = Math.floor(elvl) - Math.floor(slvl)

    if (dif > 0) setIsLevelUp(true)
    else setIsLevelUp(false)

    if (isLevelUp && badge?.status !== 'active') {
      const timeout = setTimeout(() => {
        setDisplayMode(DISPLAY_MODE.LEVEL_UP)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }

    if (badge?.status === 'active') {
      const timeout = setTimeout(() => {
        setDisplayMode(DISPLAY_MODE.BADGE)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }

    if (badge?.status === 'inProgress') {
      const timeout = setTimeout(() => {
        setDisplayMode(DISPLAY_MODE.BADGE_PROGRESS)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [profile, startLevel, endLevel, reset, badge?.status])

  const resetLocationState = () => {
    dispatch(setLocationState({}))
    setDisplayMode(DISPLAY_MODE.POST_INFO)
  }

  useEffect(() => {
    rProfileCircle?.current?.play()
  }, [])

  const handleCheckPost = () => {
    resetLocationState()
    dispatch(setNavbar(NAV))
    dispatch(clearFilters())
    dispatch(setSelectedBubble(null))

    if (userEvent) {
      router.push({
        pathname: '/event/' + userEvent.objectId,
        query: { designs: true }
      })
      return
    }

    if (challenge) {
      router.push({
        pathname: '/challenges/' + challenge.objectId,
        query: { designs: true }
      })
      return
    }

    router.push('/design/' + savedUserInspiration.objectId)
  }

  if (locationState?.data?.type !== 'postSuccess') return null

  const coins = coinIncrease || userEventCoinReward

  return (
    <>
      <PostSuccessModalWrapper>
        <PostSuccessModalBody>
          <CloseButton onClick={resetLocationState}>
            <img src={closeImg} alt="" />
          </CloseButton>
          <HeaderRow>
            <EditPostButton onClick={() => setShowEditModal(true)}>
              <img src={editPostImg} alt="" />
              Edit Post
            </EditPostButton>
          </HeaderRow>
          {(displayMode === DISPLAY_MODE.POST_INFO ||
            displayMode === DISPLAY_MODE.BADGE_PROGRESS) && (
            <>
              <PostImage>
                <CanvasWrapper>
                  <DesignCanvas
                    displayAnimation={true}
                    displayRichContent={true}
                    data={savedUserInspiration}
                    borderRadius={0}
                  />
                </CanvasWrapper>
                <AvatarWrapper>
                  <ProfileCircle
                    ref={rProfileCircle}
                    picture={picture}
                    startLevel={sLevel}
                    endLevel={eLevel}
                    autoStart
                  />
                </AvatarWrapper>
              </PostImage>
              <PostSuccessModalInfoContent>
                <Title>{isTutorial ? 'Welcome Aboard' : 'Great Job'}!</Title>
                {isTutorial && (
                  <>
                    <EventDescription>
                      You’ve earned a badge shown on your profile and
                      <br /> <b>+100</b> Dcoins
                    </EventDescription>
                    <TutorialBadges>
                      <img src={manyCoins} alt="" />
                      +
                      <img src={newUserBadge} alt="" />
                    </TutorialBadges>
                  </>
                )}
                {displayMode !== DISPLAY_MODE.BADGE_PROGRESS && !isTutorial && (
                  <>
                    {userEventCoinReward && (
                      <EventDescription>
                        Winners will earn <img src={coinImg} alt="" /> 2000{' '}
                        <img src={winBadgeIcon} alt="" /> 1 <br />
                        Results will be ready one day after the event end
                      </EventDescription>
                    )}
                    <EarningLine>
                      {(coinIncrease || userEventCoinReward) && (
                        <>
                          <CoinIcon
                            width={24}
                            height={24}
                            style={{ marginRight: 3 }}
                          />
                          <span>
                            + {coinIncrease || userEventCoinReward} Dcoins
                          </span>
                        </>
                      )}
                      {exp && (
                        <>
                          <ExpIcon
                            width={28}
                            height={28}
                            style={{ marginLeft: 10, marginRight: 1 }}
                          />
                          <span>+ {exp} EXP</span>
                        </>
                      )}
                    </EarningLine>
                  </>
                )}
                {displayMode === DISPLAY_MODE.BADGE_PROGRESS && (
                  <BadgeProgress data={badgeReward} />
                )}
                {!isTutorial && (
                  <SecondarySubtitle>
                    {isLevelUp === true &&
                      'Leveled up to L' + Math.floor(endLevel || 0) + '! '}
                    {coins > 0 && 'Enjoy +' + coins + ' Dcoins reward. '}
                    {badgeStatus &&
                      badgeStatus === 'inProgress' &&
                      'You need ' +
                        Math.abs(step) +
                        ' more designs to earn the '}
                    {badgeStatus && badgeStatus === 'inProgress' && (
                      <strong>{badge.badge.title}</strong>
                    )}
                    {badgeStatus && badgeStatus === 'inProgress' && ' badge. '}
                    {badgeStatus &&
                      badgeStatus === 'active' &&
                      'You have earned the '}
                    {badgeStatus && badgeStatus === 'active' && (
                      <strong>{badge.badge.title}</strong>
                    )}
                    {badgeStatus && badgeStatus === 'active' && ' badge! '}
                  </SecondarySubtitle>
                )}
              </PostSuccessModalInfoContent>
            </>
          )}
          {displayMode === DISPLAY_MODE.LEVEL_UP && (
            <AchievementInfoContainer>
              <LevelUpAvatarWrapper>
                <ProfileCircle
                  ref={rProfileCircle}
                  picture={picture}
                  startLevel={startLevel}
                  endLevel={endLevel}
                  autoStart
                />
              </LevelUpAvatarWrapper>
              <AchievementInfo>
                <AchievementTitle>Congratulations!</AchievementTitle>
                <EarningLine big>
                  <>
                    <CoinIcon
                      width={32}
                      height={32}
                      style={{ marginRight: 8 }}
                    />
                    <span>+ 350 Dcoins</span>
                  </>
                </EarningLine>
                <AchievementText>
                  You have leveled up to{' '}
                  <strong>L{Math.floor(endLevel)}</strong>! Enjoy{' '}
                  <strong>350</strong> Dcoins from us!
                </AchievementText>
              </AchievementInfo>
            </AchievementInfoContainer>
          )}
          {displayMode === DISPLAY_MODE.BADGE && (
            <AchievementInfoContainer>
              <AchievementInfo>
                <BadgeImage src={badge?.currentCFImageUrl} alt="" />
                <AchievementTitle>Congratulations!</AchievementTitle>
                <AchievementText>You have earned the badge!</AchievementText>
              </AchievementInfo>
            </AchievementInfoContainer>
          )}
          <ButtonsGrid>
            {isTutorial ? (
              <Link href="/" passHref>
                <PrimaryButton onClick={resetLocationState} isLarge>
                  Start My Journey
                </PrimaryButton>
              </Link>
            ) : (
              <>
                <SecondaryButton onClick={handleCheckPost}>
                  Check Designs
                </SecondaryButton>
                <Link href="/" passHref>
                  <PrimaryButton onClick={resetLocationState}>
                    Start New
                  </PrimaryButton>
                </Link>
              </>
            )}
          </ButtonsGrid>
        </PostSuccessModalBody>
      </PostSuccessModalWrapper>
      {showEditModal && (
        <CaptionModal
          data={savedUserInspiration}
          handleClose={() => {
            setShowEditModal(false)
            resetLocationState()
          }}
        />
      )}
    </>
  )
}
