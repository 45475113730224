import Link from 'next/link'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import closeImg from '../../assets/exit.svg'
import {
  FollowAction,
  unFollowAction
} from '../../redux/actions/FollowingActions'
import {
  closeModal,
  getUserInsLikes
} from '../../redux/communityReducers/PostActionsReducer'
import formatDate from '../../utils/formatDate'
import ModalOverlay from '../common/ModalOverlay'
import * as styles from '../gift/vg.module.css'

const CardItem = ({
  data,
  userPhoto,
  giftImg,
  userName,
  objectId,
  followingDate,
  count,
  followed
}) => {
  const dispatch = useDispatch()

  const handleFollow = () => {
    dispatch(FollowAction(data.objectId))
  }

  const handleUnfollow = () => {
    dispatch(unFollowAction(data.objectId))
  }

  const directProfile = () => {
    dispatch(closeModal())
  }

  let dateFollow = formatDate(followingDate)

  let username =
    userName === undefined ? `DM_User_${data.objectId.slice(-2)}` : userName
  let userImg = userPhoto === '' ? data.cfTbImageUrl : userPhoto
  if (userImg === undefined) {
    userImg =
      'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'
  }
  return (
    <div className={styles.giftAllItemsBody}>
      <div className={styles.giftAllItemsInfo}>
        <div className={styles.giftAllItemsPhoto}>
          <Link href={'/dm/' + objectId}>
            <img src={userImg} onClick={directProfile} alt="" />
          </Link>
        </div>
        <div className={styles.userInfo}>
          <Link href={'/dm/' + objectId}>
            <p className={styles.userName} onClick={directProfile}>
              {username}
            </p>
          </Link>
          <p className={styles.followingDate}>{dateFollow}</p>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FollowButton
          following={followed}
          handleFollow={handleFollow}
          handleUnfollow={handleUnfollow}
        />
      </div>
    </div>
  )
}

const FollowButton = ({ following, handleFollow, handleUnfollow }) => {
  if (following) {
    return (
      <button onClick={handleUnfollow} className={styles.followButtonFollowing}>
        {' '}
        Following{' '}
      </button>
    )
  } else {
    return (
      <button onClick={handleFollow} className={styles.followButton}>
        {' '}
        Follow{' '}
      </button>
    )
  }
}

const LikeList = () => {
  const dispatch = useDispatch()
  const { likeModal, numLike, userInsId, userInsLikeList } = useSelector(
    state => state.postActions
  )
  const { following } = useSelector(state => state.follows)
  const close = () => {
    dispatch(closeModal())
  }

  useEffect(() => {
    dispatch(getUserInsLikes(userInsId))
  }, [dispatch, userInsId])

  const useOutsideAlerter = (ref, closeMenu, active) => {
    const dispatch = useDispatch()

    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(closeMenu())
      }
    }

    useEffect(() => {
      if (active) document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    })
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, closeModal, likeModal)

  if (!likeModal) {
    return null
  }
  let userlist = {}
  userlist = userInsLikeList.map((user, index) => (
    <CardItem
      data={user.user}
      key={index}
      userPhohref={
        user.user.thumbProfileImageFile
          ? user.user.thumbProfileImageFile.url
          : ''
      }
      userName={user.user.uniqueDisplayName}
      followingDate={user.updatedAt}
      objectId={user.user.objectId}
      followed={following.includes(user.user.objectId)}
    />
  ))

  return (
    <ModalOverlay>
      <div className={styles.giftAllItems} ref={wrapperRef}>
        <div>
          <img
            src={closeImg}
            alt=""
            className={styles.closeButton}
            onClick={close}
          />
          <span className={styles.giftAllItemsTitle}>Likes {numLike}</span>
        </div>
        <div className={styles.giftAllItemsContent}>{userlist}</div>
        <button className={styles.sendButtonGift} onClick={close}>
          {' '}
          Close{' '}
        </button>
      </div>
    </ModalOverlay>
  )
}
export default LikeList
