import dynamic from 'next/dynamic'
import Link from 'next/link'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import closeIcon from '../../../assets/exit.svg'
import { useIsMobile } from '../../../hooks/useIsMobile'
import useOutsideAlerter from '../../../hooks/useOutsideAlerter'
import {
  closeCommentModal,
  getUserInsComments,
  sendUserInsComment
} from '../../../redux/communityReducers/PostActionsReducer'
import { ProfileImage } from '../../../routes/Profile/components/profile/ProfileImage/ProfileImage'
import formatDate from '../../../utils/formatDate'
import ModalOverlay from '../../common/ModalOverlay'
import { CommentField } from './CommentField'
import { CommentItem } from './CommentItem'
import {
  CloseIcon,
  CommentFooter,
  CommentFooterImg,
  CommentFooterWrapper,
  CommentInfoLine,
  CommentInfoName,
  CommentInfoNumber,
  CommentModalHeader,
  CommentModalWrapper,
  CommentSendAction,
  PostDescriptionBlock,
  UserDescription,
  UserDescriptionDate,
  UserDescriptionMetadata,
  UserDescriptionTitle
} from './Comments.styles'
import { logEvent } from '../../../utils/firebase'
import { getUserAvatar } from '../../../utils/getUserAvatar'
import { re } from 'prettier'

const DMPrimaryButton = dynamic(
  () =>
    import('@decormatters/dm-theme').then(
      component => component.DMPrimaryButton
    ),
  { ssr: false }
)

let onSendCallback = null

const CommentModal = () => {
  const { me } = useSelector(state => state.profile)
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const {
    commentModal,
    commentData,
    commentLike,
    userInsIdComment,
    userInsCommentList
  } = useSelector(state => state.postActions)
  const [commentVal, setCommentVal] = useState('')
  const [replyCommentId, setReplyCommentId] = useState(null)
  const [replyCommentMark, setReplyCommentMark] = useState(null)
  const commentInputRef = useRef()

  useEffect(() => {
    dispatch(getUserInsComments(userInsIdComment))
  }, [dispatch, userInsIdComment])

  let userPhoto = getUserAvatar(me)

  const titleArr = commentData?.title
    ? commentData?.title.split(' ')
    : commentData?.title

  const close = () => {
    setCommentVal('')
    setReplyCommentId(null)
    dispatch(closeCommentModal())
  }

  const handleSendComment = () => {
    dispatch(
      sendUserInsComment({
        userInspirationId: userInsIdComment,
        text: commentVal,
        clientCreatedAt: new Date().toISOString(),
        repliedToCommentId: replyCommentId,
        onSendCallback
      })
    )
    setCommentVal('')
    setReplyCommentId(null)
  }

  const onChange = e => {
    const commentText = e.target.value

    if (replyCommentId && !commentText.includes(replyCommentMark)) {
      setReplyCommentId(null)
      setReplyCommentMark(null)
    }

    setCommentVal(commentText)
  }

  const onReply = useCallback((userName, commentId, sendCallback) => {
    // Should be two spaces    \/
    setReplyCommentMark(`@${userName}`)
    setCommentVal(`@${userName}  `)
    setReplyCommentId(commentId)
    commentInputRef.current?.focus()
    onSendCallback = sendCallback
  }, [])

  let cardItem = userInsCommentList.map(data => {
    return (
      <CommentItem
        id={data.objectId}
        user={data.user}
        key={data.objectId}
        userImg={data.user.cfTbImageUrl}
        date={data.updatedAt}
        username={data.user.uniqueDisplayName}
        text={data.text}
        giftImg={data.vgImageUrl}
        likes={data.numLikes || 0}
        replies={data.numReplies || ''}
        onReply={onReply}
        inspirationId={userInsIdComment}
      />
    )
  })

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, closeCommentModal, commentModal)

  if (!commentModal) {
    return null
  }

  const profileImage = (
    <ProfileImage
      size={isMobile ? 42 : 68}
      user={commentData.user}
      userLevel={(commentData && commentData.user.userLevel) || 0}
      small
    />
  )

  const postDescription = commentData?.title
    ? titleArr.map((item, i) => {
        if (item.includes('#')) {
          return (
            <Link
              key={i}
              role="link"
              href={`/hashtag/${encodeURIComponent(item.substr(1))}`}
              title={item}
              onClick={close}
            >
              {item}
            </Link>
          )
        } else {
          return <React.Fragment key={i}>{`${item} `}</React.Fragment>
        }
      })
    : null

  const blurListener = () => {}

  return (
    <ModalOverlay>
      <CommentModalWrapper ref={wrapperRef}>
        <CommentModalHeader>
          {!isMobile && profileImage}
          <UserDescription>
            {isMobile && profileImage}
            <UserDescriptionMetadata>
              <UserDescriptionTitle>
                {commentData.user.uniqueDisplayName}
              </UserDescriptionTitle>
              <UserDescriptionDate>
                {formatDate(commentData.createdAt)}
              </UserDescriptionDate>
            </UserDescriptionMetadata>
            {!isMobile && (
              <PostDescriptionBlock>{postDescription}</PostDescriptionBlock>
            )}
          </UserDescription>
          {isMobile && (
            <PostDescriptionBlock>{postDescription}</PostDescriptionBlock>
          )}
          <CloseIcon src={closeIcon} onClick={close} />
        </CommentModalHeader>
        <CommentInfoLine>
          <div>
            <CommentInfoName>Comments</CommentInfoName>
            <CommentInfoNumber>
              {userInsCommentList?.length} comments
            </CommentInfoNumber>
          </div>
          <div>{cardItem}</div>
        </CommentInfoLine>
        <CommentFooterWrapper>
          <CommentFooter>
            <CommentFooterImg src={userPhoto} alt="" />
            <CommentField
              inputRef={commentInputRef}
              value={commentVal}
              onChange={onChange}
              onBlur={blurListener}
            />
          </CommentFooter>
          <CommentSendAction>
            <DMPrimaryButton
              disabled={!commentVal}
              type="button"
              onClick={handleSendComment}
            >
              Submit
            </DMPrimaryButton>
          </CommentSendAction>
        </CommentFooterWrapper>
      </CommentModalWrapper>
    </ModalOverlay>
  )
}
export default CommentModal
