import React from 'react'
import { Mention } from 'react-mentions'
import { useDispatch } from 'react-redux'
import { searchUsers } from '../../../redux/actions/FollowingActions'
import ProfileImage from '../../../routes/Profile/components/profile/ProfileImage'
import { getUsername } from '../../../utils/getUsername'
import {
  CommentFieldSuggestionLine,
  CommentFooterInput,
  CommentFooterInputWrapper
} from './Comments.styles'

const CommentFieldSuggestion = ({ entry }) => {
  return (
    <CommentFieldSuggestionLine>
      <ProfileImage size={40} user={entry} showLevel={false} />
      <span>{entry.display}</span>
    </CommentFieldSuggestionLine>
  )
}

export const CommentField = ({ inputRef, ...props }) => {
  const dispatch = useDispatch()

  const getMentionData = async (query, callback) => {
    dispatch(
      searchUsers(query, ({ result: { users } }) => {
        callback(
          users.map(user => ({
            ...user,
            id: user.objectId,
            display: getUsername(user)
          }))
        )
      })
    )
  }

  return (
    <CommentFooterInputWrapper>
      <CommentFooterInput
        inputRef={inputRef}
        placeholder="Add comments..."
        singleLine={true}
        allowSuggestionsAboveCursor={true}
        {...props}
      >
        <Mention
          trigger="@"
          appendSpaceOnAdd={true}
          markup="@__display__ "
          displayTransform={(_, display) => `@${display}`}
          renderSuggestion={entry => <CommentFieldSuggestion entry={entry} />}
          data={getMentionData}
        />
      </CommentFooterInput>
    </CommentFooterInputWrapper>
  )
}
