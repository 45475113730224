import React from 'react'
import styled from 'styled-components'

import ModalOverlay from '../../common/ModalOverlay'

const Container = styled.div`
  width: 680px;
  height: 560px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 95vw;
    height: 80vh;
    max-width: 332px;
    max-height: 559px;
    justify-content: end;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Image = styled.img`
  height: 260px;
  margin-top: 70px;

  @media only screen and (max-width: 600px) {
    max-width: 210px;
    object-fit: contain;
  }
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: 26px;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    marin-bottom: 16px;
    margin-top: 0;
  }
`

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #777777;
  margin-top: 26px;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.02em;
    margin-bottom: 40px;
  }
`

const SuccessButton = styled.button`
  width: 100px;
  height: 42px;
  background: #ff5e6d;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  border-radius: 37px;
  border: none;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 50px);
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    height: 42px;
    width: 180px;
    border-radius: 37px;
    position: static;
    margin-bottom: 37px;
  }
`

const GiftSuccessScreen = ({ url, close }) => {
  return (
    <ModalOverlay fullscreen>
      <Container>
        <Image alt="" src={url} />
        <Title>Success!</Title>
        <Subtitle>You just sent the gift</Subtitle>
        <SuccessButton onClick={close}>OK</SuccessButton>
      </Container>
    </ModalOverlay>
  )
}

export default GiftSuccessScreen
