import React from 'react'
import { useSelector } from 'react-redux'

import useModal from '../../hooks/useModal'

import { APPID } from '../../constants/ApiEndpoints'
import { getUserToken } from '../../utils/fetchUtils'
import { MembershipModal } from './components/MembershipModal'
import PurchaseProvider from './components/PurchaseProvider'

export const Modal = ({ onlyContent, hideBundles, disableElements }) => {
  const { isAuthenticated } = useSelector(state => state.auth)
  const { showPurchaseCoins, showPurchaseMembershipModal } = useSelector(
    state => state.designer
  )
  const dm = useSelector(state => state.dm)
  const isOpened =
    onlyContent ||
    (isAuthenticated &&
      !!(
        showPurchaseCoins ||
        showPurchaseMembershipModal ||
        dm.showPurchaseCoins ||
        dm.showPurchaseMembershipModal
      ))

  useModal(isOpened)

  if (!isOpened) return null

  return (
    <PurchaseProvider
      scheme={{}}
      appid={parseInt(APPID)}
      token={getUserToken()}
      disableElements={disableElements}
    >
      <MembershipModal onlyContent={onlyContent} hideBundles={hideBundles} />
    </PurchaseProvider>
  )
}
