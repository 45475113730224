import { MentionsInput } from 'react-mentions'
import styled from 'styled-components'

export const CommentModalWrapper = styled.div`
  position: relative;
  width: 680px;
  height: 771px;
  display: flex;
  flex-direction: column;

  @media screen and (max-height: 780px) {
    height: 80vh;
    margin-top: -5%;
  }

  @media screen and (max-width: 786px) {
    height: 50vh;
    width: 76%;
    margin-top: -20%;
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: white;
    margin-top: 0;

    ${props =>
      props.shrink &&
      `
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
    `}
  }
`

export const CommentModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f5f5f5;
  padding: 20px 22px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media screen and (max-width: 786px) {
    display: block;
  }

  @media screen and (max-width: 600px) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`

export const UserDescription = styled.div`
  margin-left: 15px;

  @media screen and (max-width: 786px) {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-bottom: 7px;
  }
`

export const UserDescriptionMetadata = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 786px) {
    display: block;
    margin-left: 15px;
  }
`

export const UserDescriptionTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-right: 10px;
`

export const UserDescriptionDate = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #8b8b8b;
`

export const PostDescriptionBlock = styled.p`
  margin: 11px 0 0 0;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  & > a {
    color: #518cff;
    margin-right: 3px;
    display: inline-block;
  }
`

export const CloseIcon = styled.img`
  position: absolute;
  height: 40px;
  width: 40px;
  right: 8px;
  top: 8px;
`

export const CommentInfoLine = styled.div`
  padding: 20px 40px;
  overflow: auto;
  background: #ffffff;
  flex: 1;

  & > div:first-child {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 786px) {
    padding: 16px 22px 16px 15px;
  }
`

export const CommentInfoName = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  margin-right: 25px;
`

export const CommentInfoNumber = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #8b8b8b;
`

export const CommentFooterWrapper = styled.div`
  background: #f5f5f5;
  padding-bottom: 26px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media screen and (max-width: 600px) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`

export const CommentFooter = styled.div`
  padding: 10px 40px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 786px) {
    padding: 10px 13px;
  }
`

export const CommentFooterImg = styled.img`
  margin-right: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  @media screen and (max-width: 786px) {
    width: 32px;
    height: 32px;
    border: 2px solid #ffffff;
    margin-right: 5px;
  }
`

export const CommentFooterInputWrapper = styled.div`
  width: 520px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 13px;
  padding: 15px;

  @media screen and (max-width: 600px) {
    padding: 10px;
  }
`

export const CommentFooterInput = styled(MentionsInput)`
  input {
    border: none;
    box-sizing: border-box;
    line-height: 13px;
  }

  & > div {
    background-color: transparent !important;
    width: 287px;
  }

  ul {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 317px;
    overflow: auto;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`

export const CommentFieldSuggestionLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 12px 3px;

  &:hover {
    background: #f5f5f5;
  }

  & span {
    margin-left: 14px;
    font-size: 14px;
  }
`

export const CommentItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding-left: ${props => (props.reply ? '80px' : '0px')};

  @media screen and (max-width: 786px) {
    padding-left: ${props => (props.reply ? '60px' : '0px')};
  }
`

export const CommentItemPhoto = styled.div`
  margin-right: 25px;
  position: relative;

  & img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  @media screen and (max-width: 786px) {
    margin-right: 8px;
  }
`

export const CommentItemContent = styled.div`
  width: 550px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 50px;
  margin-left: 12px;
`

export const CommentItemContentHeader = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const CommentItemContentUsername = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
`

export const CommentItemContentDate = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  margin-top: 5px;
  color: #8b8b8b;
`

export const CommentContentText = styled.div`
  margin-left: 10px;
  font-size: 13px;
  line-height: 25px;
  color: #000000;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  & a {
    color: #518cff;
  }
`

export const CommentItemActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }
`

export const CommentExpandHideButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  margin-left: 75px;
  font-weight: bold;
  font-size: 12px;
  color: #8b8b8b;
  display: flex;
  align-items: center;
  padding-left: ${props => (props.indent ? '75px' : '0')};
  cursor: pointer;

  @media screen and (max-width: 786px) {
    padding-left: 0;
  }

  & img {
    width: 8px;
    margin-right: 7px;
  }
`

export const CommentLikeReplyActions = styled.div`
  margin-right: 10px;

  & button {
    width: auto;
  }
`

export const CommentReplyButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  & img {
    opacity: 0.7;
  }

  & span {
    font-size: 11px;
    font-weight: bold;
    color: #8b8b8b;
    margin-left: 7px;
  }

  &:hover {
    & img {
      opacity: 0.9;
    }
  }
`

export const CommentSendAction = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;

  & button[disabled] {
    background-color: #8b8b8b;
    color: white;
    border: 2px solid #8b8b8b;
  }

  @media screen and (max-width: 786px) {
    justify-content: center;
    padding-right: 0;

    & button {
      width: 252px;
    }
  }
`

export const MoreButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  text-align: right;
  border-radius: 50%;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

export const MoreIcon = styled.img`
  cursor: pointer;
`
