import styled from 'styled-components'

export const DeleteConfirmationModalContainer = styled.div`
  width: 530px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & button {
    width: 160px;
    margin: 0 10px;
  }

  @media (max-width: 600px) {
    margin-top: 15vh;
    width: 95vw;
    max-width: 95vw;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }
`

export const DeleteConfirmationTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 27px;
`

export const DeleteConfirmationText = styled.p`
  font-size: 14px;
  line-height: 18px;
  max-width: 368px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    max-width: 78vw;
  }
`
