import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setMembershipSidebarProps, setShowPurchaseCoins, setShowMembershipModal, setShowBundles, setPreselectPackage } from '../../../redux/designerReducers/designerReducer'
import coinIcon from '../../../assets/coin.svg'
import membershipBGWebp from '../../../assets/membership/MembershipBG.webp'
import membershipOnlyBGWebp from '../../../assets/membership/MembershipOnlyBG.webp'

import ModalOverlay from '../../common/ModalOverlay'
import Packages from './Packages'
import Dcoins from './Dcoins'
import Bundles from './Bundles'
import { usePayment } from '../context/payment'
import { LoadingIndicator } from '../../Loading'

import PaymentModal from './PaymentModal'
import SuccessModal from './SuccessModal'
import { gotoSignUp } from '../../../redux/actions/AuthActions'
import dynamic from 'next/dynamic'
import { FeatureSlider } from './FeatureSlider'
import { useIsMobile } from '../../../hooks/useIsMobile'
import MobileMembersipPackages from '../../MembershipLandingAdvert/MobileMembersipPackages'

const FeatureModal = dynamic(() => import('../../DesignerModals/FeatureModal'), { ssr: false })

const Root = styled.div`
  background-color: #fff;
  width: min(90vw, 720px);
  height: min(810px, 90vh);
  border-radius: 10px;
  overflow-y: auto;
  background: #fbf3f3;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
`

const Header = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 10;

  & img {
    vertical-align: bottom;
    height: 26px;
    width: 26px;
  }
`

const Close = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  position: relative;
  left: 15px;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    background: black;
    width: 15px;
    height: 2px;
    border-radius: 0 0 2px 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transform-origin: left;
  }

  &:after {
    border-radius: 2px 2px 0 0;
    transform: translateX(-50%) translateY(50%) rotate(-45deg);
  }
`

const MembershipContainer = styled.div`
  background-image: url(${membershipBGWebp});
  background-position: bottom;
  background-size: cover;
  padding: 13px 0 100px;
  overflow-x: hidden;

  ${props =>
    props.isMembershipOnly
      ? `
    height: min(755px, calc(90vh - 55px));
    background-position: top;
    padding-bottom: 0px;
    box-sizing: border-box;
    background-image: url(${membershipOnlyBGWebp});

    @media only screen and (max-width: 600px) {
      height: calc(100% - 55px);
    }
  `
      : ''}
`

const CoinsContainer = styled.div`
  padding: 0 50px 25px;

  @media only screen and (max-width: 600px) {
    padding: 0 15px 25px;
    margin-top: -25px;
  }
`

const Title = styled.span`
  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 700;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`

export const MembershipModal = ({ onlyContent, hideBundles }) => {
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const coinsRef = useRef(null)
  const bundlesRef = useRef(null)
  const { isAuthenticated } = useSelector(state => state.auth)
  const me = useSelector(state => state.profile.me)

  const { showPurchaseCoins, showPurchaseMembershipModal, showBundles, preselectPackage } = useSelector(state => state.designer)

  const [featureModalData, setFeatureModalData] = useState(null)
  const [successModalData, setSuccessModalData] = useState()

  const {
    getProducts,
    membershipPackages,
    membershipMessage,
    bundlesList,
    coinPackages,
    selectPackage,
    selectedPackage,
    createCustomer,
    createSubscription,
    createRevenueCatReceipt,
    createRevenueCatPurchaseReceipt,
    getPaymentIntentCoins,
    clientSecret
  } = usePayment()

  useEffect(() => {
    getProducts()
  }, [])

  const handleClose = () => {
    dispatch(setShowPurchaseCoins(false))
    dispatch(setShowMembershipModal(false))
    dispatch(setShowBundles(false))
    dispatch(setMembershipSidebarProps({}))
    dispatch(setPreselectPackage(null))
  }

  const openLogin = () => {
    dispatch(gotoSignUp())
  }

  const onSelectPackage = (...props) => {
    if (!isAuthenticated) {
      openLogin()

      return
    }

    selectPackage(...props)
  }

  useEffect(() => {
    if (preselectPackage) {
      onSelectPackage(preselectPackage)
    } else if (!showPurchaseCoins) {
      const weeklyMembership = membershipPackages.find(item => item.name === 'Weekly')

      if (weeklyMembership) {
        onSelectPackage(weeklyMembership)
      }
    }
  }, [membershipPackages, preselectPackage, showPurchaseCoins])

  useEffect(() => {
    if (!showBundles && coinsRef?.current && showPurchaseCoins && !!membershipPackages?.length) {
      coinsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [showPurchaseCoins, membershipPackages?.length])

  useEffect(() => {
    if (bundlesRef?.current && showBundles && !!bundlesList?.length) {
      bundlesRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [showBundles, bundlesList?.length])

  if (!membershipPackages?.length) {
    if (onlyContent) return null

    return (
      <ModalOverlay fullscreen onClose={handleClose}>
        <LoadingIndicator loading />
      </ModalOverlay>
    )
  }

  if (!!featureModalData) return <FeatureModal data={featureModalData} setData={setFeatureModalData} />

  if (!!successModalData) return <SuccessModal data={successModalData} onClose={handleClose} />

  if (!!selectedPackage)
    return (
      <PaymentModal
        selected={selectedPackage}
        onSelect={onSelectPackage}
        createCustomer={createCustomer}
        createSubscription={createSubscription}
        createRevenueCatReceipt={createRevenueCatReceipt}
        createRevenueCatPurchaseReceipt={createRevenueCatPurchaseReceipt}
        userid={me.objectId}
        getPaymentIntentCoins={getPaymentIntentCoins}
        clientSecret={clientSecret}
        onSuccess={setSuccessModalData}
      />
    )

  const modalContent = (
    <>
      <MembershipContainer isMembershipOnly={showPurchaseMembershipModal}>
        <FeatureSlider />
        {isMobile ? (
          <MobileMembersipPackages joinStyle={{ marginTop: '12px' }} packages={membershipPackages} message={membershipMessage} onSelect={onSelectPackage} />
        ) : (
          <Packages allowShrink={true} packages={membershipPackages} message={membershipMessage} onSelect={onSelectPackage} />
        )}
      </MembershipContainer>
      {showPurchaseMembershipModal || (
        <CoinsContainer ref={coinsRef}>
          <Title>Refill Your Dcoins</Title>
          <Dcoins packages={coinPackages} onSelect={onSelectPackage} />
          {!hideBundles && !!bundlesList?.length && (
            <div ref={bundlesRef}>
              <Title>Bundles</Title>
              <Bundles bundles={bundlesList} onSelect={onSelectPackage} />
            </div>
          )}
        </CoinsContainer>
      )}
    </>
  )

  if (onlyContent) return modalContent

  return (
    <>
      <ModalOverlay fullscreen onClose={handleClose}>
        <Root>
          <Header>
            <Close onClick={handleClose} />
            <span>
              My Balance <img src={coinIcon} alt="dcoin" /> {me?.numCoins}
            </span>
            <div />
          </Header>
          {modalContent}
        </Root>
      </ModalOverlay>
    </>
  )
}
