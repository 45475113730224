import React from 'react'
import { DeleteConfirmationModal } from '../DeleteConfirmationModal/DeleteConfirmationModal'
import CommentModal from '../DesignDetail/Comments/CommentModal'
import LikeList from '../DesignDetail/LikeList'
import ReportModal from '../DesignDetail/ReportModal'
import { FilterInfoModal } from '../Feed/FilterInfoModal'
import AllGiftModals from '../gift/AllGiftModals'
import VirtualGiftModal from '../gift/VirtualGiftModal'
import ShareBox from '../social/ShareBox'
import MembershipModal from '../MembershipModal'
import DailyRewardsModal from '../DailyRewardsModal'
import CreateUsernameModal from '../CreateUsernameModal'
import PostSuccessModal from '../PostSuccessModal'
import { DailyRewardsChecker } from './DailyRewardsChecker'

const PageModals = () => {
  return (
    <>
      <MembershipModal />
      <DailyRewardsModal />
      <VirtualGiftModal />
      <AllGiftModals />
      <LikeList />
      <ReportModal />
      <DeleteConfirmationModal />
      <CommentModal />
      <ShareBox />
      <FilterInfoModal />
      <CreateUsernameModal />
      <PostSuccessModal />
      <DailyRewardsChecker />
    </>
  )
}

export default PageModals
