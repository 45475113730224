import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  margin-top: 20px;

  @media only screen and (max-width: 600px) {
    margin-top: 15px;
    padding-bottom: 100px;
  }
`

const Bundle = styled.button`
  width: 100%;
  height: auto;
  aspect-ratio: 620 / 180;
  padding: 0;
  border: 0;
  background: none;
  margin-bottom: 26px;
  cursor: pointer;
  filter: drop-shadow(0px 2px 6px rgba(255, 94, 109, 0.2));
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  & img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Bundles = ({ bundles, onSelect }) => {
  if (!bundles?.length) return null

  return (
    <>
      <Root>
        {bundles.map(item => (
          <Bundle
            key={item.id}
            onClick={() => {
              onSelect(item)
            }}
          >
            <img src={item.imageUrl} />
          </Bundle>
        ))}
      </Root>
    </>
  )
}

export default Bundles
