import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
  width: 140px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  border: 2px solid #fff;
  border-color: ${({ selected }) => (selected ? '#FF5E6D' : '#fff')};
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 24px;
  margin: 9px;

  &:hover {
    border-color: #ff5e6d;
  }

  @media screen and (max-width: 700px) {
    width: 80px;
    height: 115px;
    margin: 5px;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;

  @media screen and (max-width: 700px) {
    font-size: 10px;
    font-weight: 500;
  }
`

const Cost = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #8b8b8b;

  @media screen and (max-width: 700px) {
    font-size: 12px;
  }
`

const Image = styled.img`
  height: 75px;

  @media screen and (max-width: 700px) {
    height: 50px;
  }
`

const GiftCard = ({
  title,
  url,
  coins,
  selected,
  hovered,
  onClick,
  onMouseEnter,
  onMouseLeave,
  gifUrl
}) => (
  <Card
    selected={selected}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <Image
      loading="lazy"
      src={selected ? gifUrl : hovered ? gifUrl : url}
      alt={title}
    />
    <Title>{title}</Title>
    <Cost>{coins} dcoins</Cost>
  </Card>
)

export default GiftCard
